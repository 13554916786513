export const defaultMaxWidth = '1920px';

// this should line up with the timer bar
export const routeDelay = 3000;
export const shortRouteDelay = 1500;

// date stuff
export const dateOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

export const errorColor = '#dc3545';
