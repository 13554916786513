import React, { Component } from 'react';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import { getFullTableDataVisibility, getCreateAttachmentVisibility } from '../../helpers/VisibilityHelpers';
import { ATTACHMENT_ICONS } from '../../constants';

class Attachments extends Component {
  constructor(props) {
    super(props);
    const canSeeFullTableData = getFullTableDataVisibility();
    const canCreateAttachment = getCreateAttachmentVisibility();
    this.state = {
      attachments: [],
      loading: true,
      canSeeFullTableData,
      canCreateAttachment,
      attachmentsRoute: canSeeFullTableData ? 'super-attachments' : 'enterprise-attachments',
    };
  }

  componentDidMount() {
    this.getAttachments();
  }

  getAttachments = async () => {
    try {
      const { attachmentsRoute } = this.state;
      const results = await apiRequest('GET', attachmentsRoute);
      let data = [];
      if (results.data) {
        data = results.data;
      }
      this.setState({ attachments: data, loading: false });
    } catch (error) {
      console.error(error);
    }
  };

  listAttachmentsData = () => {
    const { attachments, canSeeFullTableData } = this.state;
    const output = [];
    for (const attachment of attachments) {
      output.push(
        <tr key={attachment.id}>
          {canSeeFullTableData ? <td>{attachment.id}</td> : null}
          {canSeeFullTableData ? <td>{attachment.beacon_id}</td> : null}
          <td>{attachment.beacon_title}</td>
          <td>
            <img src={ATTACHMENT_ICONS[attachment.attachment_type]} alt={attachment.attachment_type} />
          </td>
          <td className='truncate'>{attachment.attachment_value}</td>
          <td>
            <Link to={`/admin/attachments/${attachment.id}`}>
              <button className='btn btn-secondary' type='button'>
                edit
              </button>
            </Link>
          </td>
        </tr>,
      );
    }
    return output;
  };

  render() {
    const { loading, canSeeFullTableData, canCreateAttachment } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          {canCreateAttachment ? (
            <div className='my-5'>
              <h2>CREATE ATTACHMENT</h2>
              <Link to='/admin/attachments/create'>
                <button className='btn btn-primary btn-fancy py-3 px-4' type='button'>
                  Add Attachment +
                </button>
              </Link>
            </div>
          ) : null}
          <div className='mt-5'>
            <h2>MANAGE ATTACHMENTS</h2>
            <table className='table table-striped'>
              <thead>
                <tr>
                  {canSeeFullTableData ? <th>ID</th> : null}
                  {canSeeFullTableData ? <th>Bcn. ID</th> : null}
                  <th style={{ width: '25%' }}>Bcn. Name</th>
                  <th>Type</th>
                  <th style={{ width: '75%' }}>Value</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>{this.listAttachmentsData()}</tbody>
            </table>
          </div>
        </>
      );
    }
  }
}

export default Attachments;
