// NOTE: if updated here, update in yondar-api > PasswordHelpers.js
/** TODO: get a better one of these that has feedback as you're typing so we can display a nice message before even hitting go
 * whatever we find needs to work both here AND on the api, so we are sure we're checking the password correctly both places.
 * we want to check both places because on the client it will be fast and save some traffic, but also we never trust the client.
 */

export function PasswordCheck(password) {
  let score = 0;
  const badRegex = /^(?=.*[A-Za-z])(?=.*\d)[\dA-Za-z]{8,}$/; // at least 8 chars, 1 letter, 1 number, no specials
  const poorRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\dA-Za-z]{8,}$/; // at least 8 chars, 1 lower, 1 upper, 1 number, no specials
  const moderateRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-])[\w!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~-]{8,}$/; // at least 8 chars, 1 letter, 1 number, 1 special
  const goodRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-])[\w!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~-]{8,}$/; // at least 8 chars, 1 lower, 1 upper, 1 number, 1 special
  const excellentRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-])[\w!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~-]{10,}$/; // at least 10 chars, 1 lower, 1 upper, 1 number, 1 special
  if (badRegex.test(password)) {
    score = 1;
  }
  if (poorRegex.test(password)) {
    score = 2;
  }
  if (moderateRegex.test(password)) {
    score = 3;
  }
  if (goodRegex.test(password)) {
    score = 4;
  }
  if (excellentRegex.test(password)) {
    score = 5;
  }

  return score;
}
