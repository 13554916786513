import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import Loading from '../Loading';

class Rooms extends Component {
  constructor(props) {
    super(props);
    this.state = { rooms: [], loading: true };
  }

  componentDidMount() {
    this.getRooms();
  }

  getRooms = async () => {
    try {
      const results = await apiRequest('GET', 'rooms');
      let data = [];
      if (results.data) {
        data = results.data;
      }
      this.setState({ rooms: data, loading: false });
    } catch (error) {
      console.error(error);
    }
  };

  listRoomsData = () => {
    const { rooms } = this.state;
    const output = [];
    for (const room of rooms) {
      output.push(
        <tr key={room.id}>
          <td>{room.url} </td>
          <td>
            <Link to={`/admin/rooms/${room.id}`}>
              <button className='btn btn-secondary' type='button'>
                edit
              </button>
            </Link>
          </td>
        </tr>,
      );
    }
    return output;
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          <div className='mt-5'>
            <h2>MANAGE SPACES</h2>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th style={{ width: '100%' }}>Slug</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>{this.listRoomsData()}</tbody>
            </table>
          </div>
        </>
      );
    }
  }
}

export default Rooms;
