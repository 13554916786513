import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import Loading from '../Loading';

class adminUserTypes extends Component {
  constructor(props) {
    super(props);
    this.state = { adminUserTypes: [], loading: true };
  }

  componentDidMount() {
    this.getAdminUserTypes();
  }

  getAdminUserTypes = async () => {
    try {
      const results = await apiRequest('GET', 'admin-user-types');
      if (results.data) {
        this.setState({ adminUserTypes: results.data });
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getAdminUserTypesData = () => {
    const { adminUserTypes } = this.state;
    const output = [];
    if (adminUserTypes !== null) {
      for (const type of adminUserTypes) {
        output.push(
          <tr key={type.id}>
            <td>{type.name}</td>
            <td>
              <Link to={`/admin/admin-user-types/${type.id}`}>
                <button className='btn btn-secondary' type='button'>
                  edit
                </button>
              </Link>
            </td>
          </tr>,
        );
      }
    }
    return output;
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          <div className='mt-5'>
            <h2>MANAGE TYPES</h2>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th style={{ width: '100%' }}>Type</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>{this.getAdminUserTypesData()}</tbody>
            </table>
          </div>
        </>
      );
    }
  }
}

export default adminUserTypes;
