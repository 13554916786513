import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import { BEACON_COLORS } from '../../constants';
import Loading from '../Loading';
import { StateContext } from '../StateProvider';

const Types = () => {
  const { beaconIconsMap } = useContext(StateContext); // Essentially replaces Redux - GL
  const [loading, setLoading] = useState(true);
  const [types, setTypes] = useState([]);

  const style = {
    backgroundColor: BEACON_COLORS.default,
    borderRadius: '4px',
    padding: '4px',
    height: '40px',
    width: '40px',
    marginRight: '8px',
  };

  useEffect(() => {
    getTypes();
  }, []);

  const getTypes = async () => {
    try {
      const results = await apiRequest('GET', 'types');
      if (results.data) {
        setTypes(results.data);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const listTypesData = () => {
    const output = [];
    for (const type of types) {
      const iconList = [];
      // eslint-disable-next-line camelcase
      if (type?.allowed_icon_ids?.length > 0) {
        for (const id of type.allowed_icon_ids) {
          if (id && beaconIconsMap[id]) {
            iconList.push(<img style={style} src={beaconIconsMap[id]?.iconImage} alt={`${type.name} icon`} />);
          }
        }
      }
      output.push(
        <tr key={type.id}>
          <td className='align-middle'>{type.name}</td>
          <td>{iconList}</td>
          <td>
            <Link to={`/admin/types/${type.id}`}>
              <button className='btn btn-secondary' type='button'>
                edit
              </button>
            </Link>
          </td>
        </tr>,
      );
    }
    return output;
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className='mt-5'>
        <h2>MANAGE TYPES</h2>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Type</th>
              <th style={{ width: '100%' }}>Allowed Icons</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>{listTypesData()}</tbody>
        </table>
      </div>
    );
  }
};

export default Types;
