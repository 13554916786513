import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import Select from 'react-select';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import { getValidationErrorMessage, getErrorFields } from '../../helpers/ErrorHelpers';
import { getFullTableDataVisibility } from '../../helpers/VisibilityHelpers';
import { shortRouteDelay, errorColor } from '../../globals';
import ImageUploader from '../ImageUploader';

class AttachmentsFields extends Component {
  constructor(props) {
    super(props);
    const canSeeFullTableData = getFullTableDataVisibility();
    this.state = {
      loading: true,
      isEditing: false,
      attachment: null,
      typeSelected: '360',
      errorMessage: '',
      successMessage: '',
      messageState: '--open',
      errorFields: [],
      beacons: [],
      beaconSelected: null,
      attachmentsRoute: canSeeFullTableData ? 'super-attachments' : 'enterprise-attachments',
      beaconRoute: canSeeFullTableData ? 'super-beacons' : 'enterprise-beacons',
      typeOptions: [
        { value: '360', label: '360' },
        { value: 'audio', label: 'audio' },
        { value: 'email', label: 'email' },
        { value: 'image', label: 'image' },
        { value: 'phone', label: 'phone' },
        { value: 'video', label: 'video' },
        { value: 'website', label: 'website' },
      ],
    };
    this.attachmentValueRef = React.createRef();
    this.beaconIdRef = React.createRef();
    this.attachmentLabelRef = React.createRef();
    this.newTabRef = React.createRef();
    this.uploadRef = React.createRef();
    this.debounceReturn = debounce(() => {
      window.location.href = '/admin/attachments';
    }, shortRouteDelay);
  }

  componentDidMount() {
    this.setupPage();
  }

  setupPage = async () => {
    const { match } = this.props;
    const { id } = match.params;
    let isEditing = false;
    await this.getBeacons();
    if (id) {
      isEditing = true;
      await this.getAttachment(id);
    }
    this.setState({ loading: false, isEditing });
    this.populateAttachmentFields();
  };

  getAttachment = async id => {
    try {
      const { attachmentsRoute } = this.state;
      const results = await apiRequest('GET', `${attachmentsRoute}/${id}`);
      if (results.data) {
        this.setState({ attachment: results.data });
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  getBeacons = async () => {
    try {
      const { beaconRoute } = this.state;
      const results = await apiRequest('GET', beaconRoute);
      if (results.data) {
        this.setState({ beacons: results.data });
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  populateAttachmentFields = () => {
    const { attachment, beacons } = this.state;
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (attachment !== null) {
      const filteredBeacon = beacons.find(beacon => {
        return beacon.id === attachment.beacon_id;
      });
      const beaconSelected = { value: filteredBeacon?.id, label: filteredBeacon?.title };

      this.attachmentValueRef.current.value = attachment.attachment_value;
      this.attachmentLabelRef.current.value = attachment.attachment_label;
      this.newTabRef.current.checked = attachment.new_tab;
      this.setState({
        beaconSelected,
        typeSelected: { value: attachment.attachment_type, label: attachment.attachment_type },
      });
    } else if (params?.beacon) {
      const filteredBeacon = beacons.find(beacon => {
        return beacon.id === Number.parseInt(params.beacon);
      });
      const beaconSelected = { value: filteredBeacon?.id, label: filteredBeacon?.title };
      this.setState({
        beaconSelected,
      });
    }
  };

  submitForm = async event => {
    const { typeSelected, isEditing, attachmentsRoute, beaconSelected } = this.state;
    const { match } = this.props;
    try {
      event.preventDefault();
      const requestBody = {
        attachmentValue: this.attachmentValueRef.current.value,
        beaconId: beaconSelected.value,
        attachmentType: typeSelected.value,
        attachmentLabel: this.attachmentLabelRef.current.value,
        newTab: this.newTabRef.current.checked,
      };

      let data = null;
      if (isEditing) {
        data = await apiRequest('PUT', `${attachmentsRoute}/${match.params.id}`, requestBody);
      } else {
        data = await apiRequest('POST', attachmentsRoute, requestBody);
      }

      // scroll after request
      window.scrollTo(0, 0);

      if (!data) {
        throw new Error('request failed');
      } else if (data.error) {
        this.setState({
          errorMessage: getValidationErrorMessage(data.error),
          errorFields: getErrorFields(data.error),
          messageState: '--open',
        });
      } else {
        this.setState({ errorMessage: '', successMessage: 'Attachment Updated!' });
        this.debounceReturn();
      }
    } catch (error) {
      this.setState({
        errorMessage: 'Something went wrong. Please refresh and try again.',
        messageState: '--open',
      });
      console.error(error);
    }
  };

  getBeaconOptions = () => {
    const { beacons } = this.state;
    const returnBeacons = [];
    if (beacons) {
      for (const beacon of beacons) {
        returnBeacons.push({ value: beacon.id, label: beacon.title });
      }
    }
    return returnBeacons;
  };

  handleTypeChange = e => {
    this.setState({ typeSelected: e });
  };

  handleBeaconChange = e => {
    this.setState({ beaconSelected: e });
  };

  render() {
    const {
      loading,
      isEditing,
      errorMessage,
      messageState,
      successMessage,
      typeSelected,
      errorFields,
      typeOptions,
      beaconSelected,
    } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          <ImageUploader />
          <div className='my-5'>
            <h2>{isEditing ? 'EDIT' : 'CREATE'} ATTACHMENT</h2>
          </div>
          <form className='card p-4 fw' onSubmit={this.submitForm}>
            {errorMessage ? <p className={`alert alert-danger ${messageState}`}>{errorMessage}</p> : null}
            {successMessage ? (
              <>
                <p className='mb-0 alert alert-success --bar '>{successMessage}</p>
                <div className='bar-fill mb-3 --quick' />
              </>
            ) : null}
            <div className='grid mb-4' style={{ gridTemplateColumns: 'auto 1fr' }}>
              <label
                className={`col-form-label${errorFields.includes('attachmentValue') ? ' text-danger' : ''}`}
                htmlFor='attachmentValue'
              >
                Value (URL) *
              </label>
              <input
                className={`form-control${errorFields.includes('attachmentValue') ? ' is-invalid' : ''}`}
                id='attachmentValue'
                type='text'
                ref={this.attachmentValueRef}
              />

              <label
                className={`col-form-label${errorFields.includes('beaconId') ? ' text-danger' : ''}`}
                htmlFor='beaconId'
              >
                Beacon *
              </label>
              <Select
                styles={{
                  control: base =>
                    errorFields.includes('beaconId') ? { ...base, borderColor: errorColor } : { ...base },
                }}
                id='beaconId'
                onChange={this.handleBeaconChange}
                value={beaconSelected}
                size='10'
                options={this.getBeaconOptions()}
              />

              <label className='col-form-label' htmlFor='attachmentType'>
                Type
              </label>
              <Select
                id='attachmentType'
                type='checkbox'
                value={typeSelected}
                onChange={this.handleTypeChange}
                options={typeOptions}
              />

              <label
                className={`col-form-label${errorFields.includes('attachmentLabel') ? ' text-danger' : ''}`}
                htmlFor='attachmentLabel'
              >
                Label*
              </label>
              <input
                className={`form-control${errorFields.includes('attachmentLabel') ? ' is-invalid' : ''}`}
                id='attachmentLabel'
                type='text'
                ref={this.attachmentLabelRef}
              />

              <label className='col-form-label' htmlFor='newTab'>
                Open in New Tab
              </label>
              <input className='form-check-input mx-0 my-auto' id='newTab' type='checkbox' ref={this.newTabRef} />
            </div>
            <div className='grid' style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <button className='btn btn-primary btn-fancy fw py-2 px-4' type='submit'>
                SAVE
              </button>
              <Link to='/admin/attachments'>
                <button className='btn btn-danger btn-fancy fw py-2 px-4' type='button'>
                  BACK
                </button>
              </Link>
            </div>
          </form>
        </>
      ); // end return
    }
  }
}

export default AttachmentsFields;
