import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import { getValidationErrorMessage, getErrorFields } from '../../helpers/ErrorHelpers';
import Loading from '../Loading';
import { shortRouteDelay } from '../../globals';

class AdminRoleFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      adminRole: null,
      rolePermissions: null,
      adminRoutes: null,
      isEditing: false,
      errorMessage: '',
      successMessage: '',
      messageState: '--open',
      errorFields: [],
    };
    this.displayNameRef = React.createRef();
    this.nameRef = React.createRef();
    this.debounceReturn = debounce(() => {
      window.location.href = '/admin/admin-roles';
    }, shortRouteDelay);
  }

  componentDidMount() {
    this.setupPage();
  }

  setupPage = async () => {
    const { match } = this.props;
    const { id } = match.params;
    let isEditing = false;
    if (id) {
      await this.getAdminRole(id);
      await this.getAdminRolePermissions(id);
      isEditing = true;
    } else {
      await this.getAdminRoutes();
    }
    this.setState({ loading: false, isEditing });
    this.populateAdminRoleFields();
  };

  getAdminRole = async id => {
    const results = await apiRequest('GET', `admin-roles/${id}`);
    if (results.data) {
      this.setState({ adminRole: results.data });
    }
  };

  getAdminRolePermissions = async id => {
    const results = await apiRequest('GET', `admin-roles-permissions/${id}`);
    if (results.data) {
      this.setState({ rolePermissions: results.data });
    }
  };

  getAdminRoutes = async () => {
    const results = await apiRequest('GET', `admin-routes`);
    if (results.data) {
      this.setState({ adminRoutes: results.data });
    }
  };

  populateAdminRoleFields = () => {
    const { adminRole, isEditing, adminRoutes, rolePermissions } = this.state;
    let newRolePermissions = null;
    if (adminRole !== null) {
      this.displayNameRef.current.value = adminRole.display_name;
      this.nameRef.current.value = adminRole.name;
    }
    if (!isEditing && adminRoutes !== null) {
      newRolePermissions = this.createRolePermissionsFromRoutes();
    }
    if (rolePermissions !== null || newRolePermissions !== null) {
      this.addRefsToRolePermissions(newRolePermissions);
    }
  };

  createRolePermissionsFromRoutes = () => {
    const { adminRoutes } = this.state;
    const newRolePermissions = [];
    let i = 0;
    for (const route of adminRoutes) {
      newRolePermissions.push({
        id: i,
        can_create: false,
        can_delete: false,
        can_read: false,
        can_Update: false,
        feature_display_name: route.display_name,
        feature_id: route.id,
        feature_name: route.name,
      });
      i++;
    }
    return newRolePermissions;
  };

  addRefsToRolePermissions = newRolePermissions => {
    const { rolePermissions } = this.state;
    const permissionsWithRefs = newRolePermissions !== null ? [...newRolePermissions] : [...rolePermissions];
    for (const permission of permissionsWithRefs) {
      permission.createRef = React.createRef();
      permission.readRef = React.createRef();
      permission.updateRef = React.createRef();
      permission.deleteRef = React.createRef();
    }
    this.setState({ rolePermissions: permissionsWithRefs });

    this.updatePermissionRefsIfRefsExist();
  };

  updatePermissionRefsIfRefsExist() {
    /*
      Our ComponentDidMount runs twice. Once when the page load, and once after getting data from the database.
      The first time we do this, these ref's will exist, but the current wont.
      After the first run, the render will run, which will make the current's below exist.
      After that render we will re-mount, and then this code can be run.
    */
    const { rolePermissions } = this.state;
    const permissions = [...rolePermissions];
    for (const permission of permissions) {
      if (permission.createRef && permission.createRef.current) {
        permission.createRef.current.checked = permission.can_create;
      }
      if (permission.readRef && permission.readRef.current) {
        permission.readRef.current.checked = permission.can_read;
      }
      if (permission.updateRef && permission.updateRef.current) {
        permission.updateRef.current.checked = permission.can_update;
      }
      if (permission.deleteRef && permission.deleteRef.current) {
        permission.deleteRef.current.checked = permission.can_delete;
      }
    }
    this.setState({ rolePermissions: permissions });
  }

  submitForm = async event => {
    const { isEditing } = this.state;
    const { match } = this.props;
    try {
      event.preventDefault();
      const requestBody = {
        displayName: this.displayNameRef.current.value,
        name: this.nameRef.current.value,
        permissions: this.getPermissionsToSend(),
      };

      let data = null;
      if (isEditing) {
        data = await apiRequest('PUT', `admin-roles/${match.params.id}/edit`, requestBody);
      } else {
        data = await apiRequest('POST', 'admin-roles/create', requestBody);
      }

      // scroll after request
      window.scrollTo(0, 0);

      if (!data) {
        throw new Error('request failed');
      } else if (data.error) {
        this.setState({
          errorMessage: getValidationErrorMessage(data.error),
          errorFields: getErrorFields(data.error),
          messageState: '--open',
        });
      } else {
        this.setState({ errorMessage: '', successMessage: 'Role Updated!' });
        this.debounceReturn();
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  getPermissionsToSend = () => {
    const { rolePermissions } = this.state;
    const output = [];
    for (const permission of rolePermissions) {
      output.push({
        id: permission.id,
        featureId: permission.feature_id,
        canCreate: permission?.createRef?.current?.checked || false,
        canRead: permission?.readRef?.current?.checked || false,
        canUpdate: permission?.updateRef?.current?.checked || false,
        canDelete: permission?.deleteRef?.current?.checked || false,
      });
    }
    return output;
  };

  getPermissionFields = () => {
    const { rolePermissions } = this.state;
    const htmlOutput = [];
    if (rolePermissions !== null) {
      for (const permission of rolePermissions) {
        htmlOutput.push(
          <>
            <span className='col-form-label pe-4'>{permission.feature_display_name}</span>
            <input
              className='form-check-input mx-0 my-auto'
              id={`${permission.id}_can_create`}
              type='checkbox'
              defaultValue={permission.can_create}
              ref={permission.createRef}
            />
            <input
              className='form-check-input mx-0 my-auto'
              id={`${permission.id}_read_create`}
              type='checkbox'
              ref={permission.readRef}
            />
            <input
              className='form-check-input mx-0 my-auto'
              id={`${permission.id}_update_create`}
              type='checkbox'
              ref={permission.updateRef}
            />
            <input
              className='form-check-input mx-0 my-auto'
              id={`${permission.id}_delete_create`}
              type='checkbox'
              ref={permission.deleteRef}
            />
            <span className='col-form-label ps-4'>/{permission.feature_name}/</span>
          </>,
        );
      }
    }
    return htmlOutput;
  };

  render() {
    const { loading, isEditing, messageState, errorMessage, successMessage, rolePermissions, errorFields } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          <div className='my-5'>
            <h2>{isEditing ? 'EDIT' : 'CREATE'} ROLE</h2>
            <p>Below you can change how users with this role are allowed to interact with certain routes of the API.</p>
            <p>
              Each* route can be granted Create, Read, Update, and Delete access to this role by checking the
              corresponding box.
            </p>
            <p>
              The actual API route for a given route is shown to the right of the check boxes. Create, Read, Update, and
              Delete correspond to POST, GET, PUT, and DELETE request methods, respectively.
            </p>
            <p>
              *Routes prefixed with an asterisk only accept certain HTTP methods. Other methods will result in an error.
            </p>
            <ul>
              <li>Authentication only supports Read (GET)</li>
              <li>Logout only supports Update (PUT)</li>
              <li>Reset Password only supports Update (PUT)</li>
              <li>Uploading only supports Create (POST)</li>
            </ul>
          </div>
          <form className='card p-4 fw' onSubmit={this.submitForm}>
            {errorMessage ? <p className={`alert alert-danger ${messageState}`}>{errorMessage}</p> : null}
            {successMessage ? (
              <>
                <p className='mb-0 alert alert-success --bar '>{successMessage}</p>
                <div className='bar-fill mb-3 --quick' />
              </>
            ) : null}
            <div className='grid mb-4' style={{ gridTemplateColumns: 'repeat(5, auto) 1fr', '--bs-gap': '.5rem' }}>
              <label
                className={`col-form-label${errorFields.includes('displayName') ? ' text-danger' : ''}`}
                htmlFor='displayName'
              >
                Display Name *
              </label>
              <input
                className={`form-control${errorFields.includes('displayName') ? ' is-invalid' : ''}`}
                style={{ gridColumn: 'span 5' }}
                id='displayName'
                type='text'
                ref={this.displayNameRef}
              />
              <label
                className={`col-sm-3 col-form-label${errorFields.includes('name') ? ' text-danger' : ''}`}
                htmlFor='name'
              >
                DB Name *
              </label>
              <input
                className={`form-control${errorFields.includes('name') ? ' is-invalid' : ''}`}
                style={{ gridColumn: 'span 5' }}
                id='name'
                type='text'
                ref={this.nameRef}
                disabled={isEditing}
              />
              <b className='pe-4'>Name</b>
              <b>C</b>
              <b>R</b>
              <b>U</b>
              <b>D</b>
              <b className='ps-4'>Route</b>
              {rolePermissions !== null ? this.getPermissionFields() : null}
            </div>

            <div className='grid' style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <button className='btn btn-primary btn-fancy fw py-2 px-4' type='submit'>
                SAVE
              </button>
              <Link to='/admin/admin-roles'>
                <button className='btn btn-danger btn-fancy fw py-2 px-4' type='button'>
                  BACK
                </button>
              </Link>
            </div>
          </form>
        </>
      );
    }
  }
}

export default AdminRoleFields;
