import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { apiRequest } from '../helpers/AjaxHelpers';
import { routeDelay } from '../globals';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { email: '', error: '', success: '', errorState: '--open' };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.debounceReturn = debounce(() => {
      window.location.href = '/';
    }, routeDelay);
    this.debounceClose = debounce(() => {
      this.setState({ errorState: '--closed' });
    }, 2000);
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  resetPassword = async e => {
    try {
      e.preventDefault();
      const { email } = this.state;
      const result = await apiRequest('POST', 'forgot-password', { email });
      if (result.data) {
        this.setState({
          error: '',
          email: '',
          success: 'Password reset, please check your email. Returning to login.',
          errorState: '--open',
        });
        this.debounceReturn();
      } else if (result.error) {
        this.setState({ error: result.error, email: '', errorState: '--open' });
        this.debounceClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { error, errorState, success, email } = this.state;
    return (
      <div className='container-fluid p-0'>
        <div className='bg-header p-0 mb-5'>
          <h1 className='my-0 mx-5 pl-5 text-white text-center'>FORGOT PASSWORD</h1>
        </div>
        <div className='m-5 d-flex justify-content-center'>
          <form className='card p-4' style={{ width: '400px' }} onSubmit={this.resetPassword}>
            {error ? <p className={`alert alert-danger ${errorState}`}>{error}</p> : null}
            {success ? (
              <>
                <p className='alert alert-success --bar mb-0'>{success}</p>
                <div className='bar-fill mb-3' />
              </>
            ) : null}
            <div className='form-group mb-3  mx-0 row'>
              <input
                className='form-control'
                id='inputEmail'
                placeholder='Email'
                type='email'
                value={email}
                onChange={this.handleEmailChange}
              />
            </div>
            <div className='grid' style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <button className='btn btn-primary btn-fancy py-2 px-4 fw' type='submit'>
                SUBMIT
              </button>
              <Link to='/'>
                <button className='btn btn-secondary btn-fancy py-2 px-4 fw' type='button'>
                  BACK
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    ); // end return
  }
}

export default ForgotPassword;
