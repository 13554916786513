export function getValidationErrorMessage(errorObject) {
  const newMessage = `The following are invalid: ${getErrorFields(errorObject).join(', ')}`;
  return newMessage;
}

export function getErrorFields(errorObject) {
  const errorFields = [];
  for (const key of Object.keys(errorObject)) {
    if (!errorObject[key] && key !== 'testsPassed') {
      errorFields.push(key);
    }
  }
  return errorFields;
}
