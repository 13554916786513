import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import Loading from '../Loading';

class AdminRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = { adminRoutes: [], loading: true };
  }

  componentDidMount() {
    this.getAdminRoutes();
  }

  getAdminRoutes = async () => {
    const results = await apiRequest('GET', 'admin-routes');
    let data = [];
    if (results.data) {
      data = results.data;
    }
    this.setState({ adminRoutes: data, loading: false });
  };

  listAdminRoutesData = () => {
    const { adminRoutes } = this.state;
    const output = [];
    for (const adminRoute of adminRoutes) {
      output.push(
        <tr key={adminRoute.id}>
          <td>{adminRoute.display_name} </td>
          <td>
            <Link to={`/admin/admin-routes/${adminRoute.id}`}>
              <button className='btn btn-secondary' type='button'>
                edit
              </button>
            </Link>
          </td>
        </tr>,
      );
    }
    return output;
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          <div className='mt-5'>
            <h2>MANAGE ROUTES</h2>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th style={{ width: '100%' }}>Route</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>{this.listAdminRoutesData()}</tbody>
            </table>
          </div>
        </>
      ); // end return
    }
  }
}

export default AdminRoutes;
