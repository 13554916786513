import { BEACON_COLORS } from '../../constants';

export const getExpandedStyle = category => {
  return {
    position: 'absolute',
    color: BEACON_COLORS[category],
    transform: 'translate(-100%,-90%)',
    width: 0,
    opacity: 0,
    visibility: 'hidden',
  };
};

const BEACON_EXPANDED_WIDTH = 64; // pixels
const HALF_BEACON_EXPANDED_WIDTH = BEACON_EXPANDED_WIDTH / 2;

export const iconStyle = {
  position: 'absolute',
  transform: `translateX(calc(-50% - ${HALF_BEACON_EXPANDED_WIDTH}px)) scale(0.8)`,
};

export const beaconPlacement = {
  position: 'absolute',
  width: '64px',
  top: 'calc(50% - 88px)',
  right: 'calc(50% - 2px)',
};
