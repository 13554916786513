import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { apiRequest } from '../helpers/AjaxHelpers';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { username: '', password: '', error: '', errorState: '--open' };
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.debounceClose = debounce(() => this.setState({ errorState: '--closed' }), 2000);
  }

  handleUsernameChange(e) {
    this.setState({ username: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  attemptLogin = async e => {
    try {
      e.preventDefault();
      const { username, password } = this.state;
      const { history } = this.props;
      // https://security.stackexchange.com/questions/7057/i-just-send-username-and-password-over-https-is-this-ok
      const result = await apiRequest('POST', 'login', {
        username,
        password,
      });
      if (result.data) {
        this.setState({ error: '', password: '', username: '' });
        // store token and try to go to beacons
        window.localStorage.setItem('token', JSON.stringify(result.data));
        history.push('/admin');
      } else if (result.error) {
        this.setState({ error: result.error, username: '', password: '', errorState: '--open' });
        this.debounceClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { error, errorState, username, password } = this.state;
    return (
      <div className='container-fluid p-0'>
        <div className='bg-header p-0 mb-5'>
          <h1 className='my-0 mx-5 pl-5 text-white text-center'>SIGN IN</h1>
        </div>
        <div className='m-5 d-flex justify-content-center'>
          <form className='card p-4' style={{ width: '400px' }} onSubmit={this.attemptLogin}>
            {error ? <p className={`alert alert-danger ${errorState}`}>{error}</p> : null}
            <div className='form-group mb-3 mx-0 row'>
              <input
                className='form-control'
                id='username'
                placeholder='Username'
                type='username'
                value={username}
                onChange={this.handleUsernameChange}
              />
            </div>
            <div className='form-group mb-3 mx-0 row'>
              <input
                className='form-control'
                id='password'
                placeholder='Password'
                type='password'
                value={password}
                onChange={this.handlePasswordChange}
              />
            </div>
            <div className='grid' style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <button className='btn btn-primary btn-fancy py-2 px-4 fw' type='submit'>
                LOGIN
              </button>
              <Link to='/forgot-password'>
                <button className='btn btn-secondary btn-fancy py-2 px-4 fw' type='button'>
                  FORGOT PASS
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    ); // end return
  }
}

export default Login;
