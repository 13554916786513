import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import Link from 'react-router-dom/Link';
import Select from 'react-select';
import Loading from '../Loading';
import ImageUploader from '../ImageUploader';
import { apiRequest } from '../../helpers/AjaxHelpers';
import { getValidationErrorMessage, getErrorFields } from '../../helpers/ErrorHelpers';
import { getFullTableDataVisibility } from '../../helpers/VisibilityHelpers';
import { shortRouteDelay, errorColor } from '../../globals';
import { getPrettyDate } from '../../helpers/DateHelpers';

class WorldsFields extends Component {
  constructor(props) {
    super(props);
    const canSeeFullTableData = getFullTableDataVisibility();
    this.state = {
      loading: true,
      world: null,
      isEditing: false,
      beacons: [],
      users: [],
      beaconsSelected: [],
      userSelected: null,
      errorMessage: '',
      successMessage: '',
      messageState: '--open',
      errorFields: [],
      beaconRoute: canSeeFullTableData ? 'super-beacons' : 'enterprise-beacons',
      worldRoute: canSeeFullTableData ? 'super-worlds' : 'enterprise-worlds',
      canSeeFullTableData,
    };

    this.urlRef = React.createRef();
    this.activeRef = React.createRef();
    this.premiumRef = React.createRef();
    this.introRef = React.createRef();
    this.titleRef = React.createRef();
    this.startRef = React.createRef();
    this.endRef = React.createRef();
    this.sponsorBannerImageUrlRef = React.createRef();
    this.sponsorBannerLinkUrlRef = React.createRef();
    this.customLogoUrlRef = React.createRef();
    this.customBannerUrlRef = React.createRef();
    this.primaryColorRef = React.createRef();
    this.secondaryColorRef = React.createRef();
    this.calculateBoundsRef = React.createRef();
    this.boundsRef = React.createRef();
    this.debounceReturn = debounce(() => {
      window.location.href = '/admin/worlds';
    }, shortRouteDelay);
  }

  componentDidMount() {
    this.setupPage();
  }

  setupPage = async () => {
    try {
      const { match } = this.props;
      let isEditing = false;
      const { id } = match.params;
      await Promise.all([this.getBeacons(), this.getUsers()]);
      if (id) {
        await this.getWorld(id);
        isEditing = true;
      }
      this.setState({ loading: false, isEditing });
      this.populateWorldFields();
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  getBeacons = async () => {
    try {
      const { beaconRoute } = this.state;
      const results = await apiRequest('GET', beaconRoute);
      if (results.data) {
        const beaconList = results.data;
        for (const beacon of beaconList) {
          beacon.title = beacon.is_expired ? `${beacon.title} (expired)` : beacon.title;
        }
        this.setState({
          beacons: beaconList,
        });
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  getUsers = async () => {
    try {
      const { canSeeFullTableData } = this.state;
      if (canSeeFullTableData) {
        const results = await apiRequest('GET', 'admin-users/active');
        if (results.data) {
          this.setState({
            users: results.data,
          });
        }
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  getWorld = async worldUuid => {
    try {
      const { worldRoute } = this.state;
      const results = await apiRequest('GET', `${worldRoute}/${worldUuid}`);
      if (results.data) {
        this.setState({
          world: results.data,
        });
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  populateWorldFields = () => {
    const { world, beacons, users } = this.state;
    if (world !== null) {
      const beaconsSelected = [];
      for (const beacon of beacons) {
        if (world.worlds_beacons?.includes(beacon.uuid)) {
          beaconsSelected.push({ value: beacon.uuid, label: beacon.title });
        }
      }

      const filteredUser = users.find(user => {
        return user?.id === world.user_id;
      });
      const userSelected = { value: filteredUser?.id, label: filteredUser?.username };

      this.urlRef.current.value = world.url;
      this.activeRef.current.value = getPrettyDate(world.active);
      this.premiumRef.current.checked = world.premium;
      this.introRef.current.value = world.intro;
      this.titleRef.current.value = world.title;
      this.startRef.current.value = getPrettyDate(world.start);
      this.endRef.current.value = getPrettyDate(world.end);
      this.sponsorBannerImageUrlRef.current.value = world.sponsor_banner_image_url;
      this.sponsorBannerLinkUrlRef.current.value = world.sponsor_banner_link_url;
      this.customLogoUrlRef.current.value = world.custom_logo_url;
      this.customBannerUrlRef.current.value = world.custom_banner_url;
      this.primaryColorRef.current.value = world.primary_color;
      this.secondaryColorRef.current.value = world.secondary_color;
      this.calculateBoundsRef.current.value = false;
      this.boundsRef.current.value = world.bounds;
      this.setState({ userSelected, beaconsSelected });
    }
  };

  getBeaconOptions = () => {
    const { beacons } = this.state;
    const beaconOptions = [];
    for (const beacon of beacons) {
      beaconOptions.push({ value: beacon.uuid, label: beacon.title });
    }
    return beaconOptions;
  };

  getUserOptions = () => {
    const { users } = this.state;
    const userOptions = [];
    for (const user of users) {
      userOptions.push({ value: user?.id, label: user?.username });
    }
    return userOptions;
  };

  submitForm = async event => {
    const { beaconsSelected, isEditing, worldRoute, userSelected } = this.state;
    const { match } = this.props;
    const sendBeaconList = [...beaconsSelected].map(option => option.value);
    try {
      event.preventDefault();
      this.setState({ messageState: '--close' });
      // Convert dates back to iso so we don't lose a day in the db iso conversion (time is wibbly-wobbly) - GL
      const activeDate = this.activeRef?.current?.value ? new Date(this.activeRef.current.value).toISOString() : '';
      const endDate = this.endRef?.current?.value ? new Date(this.endRef.current.value).toISOString() : '';
      const startDate = this.startRef?.current?.value ? new Date(this.startRef.current.value).toISOString() : '';
      const requestBody = {
        url: this.urlRef.current.value,
        user_id: userSelected.value,
        active: activeDate,
        premium: this.premiumRef.current.checked,
        intro: this.introRef.current.value,
        title: this.titleRef.current.value,
        start: startDate,
        end: endDate,
        sponsor_banner_image_url: this.sponsorBannerImageUrlRef.current.value,
        sponsor_banner_link_url: this.sponsorBannerLinkUrlRef.current.value,
        custom_logo_url: this.customLogoUrlRef.current.value,
        custom_banner_url: this.customBannerUrlRef.current.value,
        primary_color: this.primaryColorRef.current.value,
        secondary_color: this.secondaryColorRef.current.value,
        worlds_beacons: sendBeaconList,
        calculate_bounds: this.calculateBoundsRef.current.checked,
        bounds: this.boundsRef.current.value,
      };

      let data = null;
      if (isEditing) {
        data = await apiRequest('PUT', `${worldRoute}/${match.params.id}`, requestBody);
      } else {
        data = await apiRequest('POST', worldRoute, requestBody);
      }

      // scroll after request
      window.scrollTo(0, 0);

      if (!data) {
        throw new Error('request failed');
      } else if (data.error) {
        this.setState({
          errorMessage: getValidationErrorMessage(data.error),
          errorFields: getErrorFields(data.error),
          messageState: '--open',
        });
      } else {
        this.setState({ errorMessage: '', successMessage: 'World Updated!' });
        this.debounceReturn();
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  handleBeaconListChange = e => {
    this.setState({ beaconsSelected: e });
  };

  handleUserChange = e => {
    this.setState({ userSelected: e });
  };

  render() {
    const {
      loading,
      isEditing,
      errorMessage,
      messageState,
      successMessage,
      world,
      userSelected,
      beaconsSelected,
      errorFields,
      canSeeFullTableData,
    } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          <ImageUploader />
          <div className='my-5'>
            <h2>{isEditing ? 'EDIT' : 'CREATE'} WORLD</h2>
          </div>
          <form className='card p-4 fw' onSubmit={this.submitForm}>
            {errorMessage ? <p className={`alert alert-danger ${messageState}`}>{errorMessage}</p> : null}
            {successMessage ? (
              <>
                <p className='mb-0 alert alert-success --bar '>{successMessage}</p>
                <div className='bar-fill mb-3 --quick' />
              </>
            ) : null}
            <div className='grid mb-4' style={{ gridTemplateColumns: 'auto 1fr' }}>
              {isEditing && world && canSeeFullTableData ? (
                <>
                  <span className='col-form-label'>ID: </span>
                  <span>{world.id}</span>
                </>
              ) : null}
              <label
                className={`col-form-label${errorFields.includes('url') ? ' text-danger' : ''}`}
                htmlFor='url'
                hidden={!canSeeFullTableData}
              >
                URL *
              </label>
              <input
                className={`form-control${errorFields.includes('url') ? ' is-invalid' : ''}`}
                id='url'
                type='text'
                ref={this.urlRef}
                hidden={!canSeeFullTableData}
              />

              <label
                className={`col-form-label${errorFields.includes('user_id') ? ' text-danger' : ''}`}
                htmlFor='userId'
                hidden={!canSeeFullTableData}
              >
                Owner ID *
              </label>
              {canSeeFullTableData ? (
                <Select
                  id='userId'
                  styles={{
                    control: base =>
                      errorFields.includes('userId') ? { ...base, borderColor: errorColor } : { ...base },
                  }}
                  value={userSelected}
                  onChange={this.handleUserChange}
                  options={this.getUserOptions()}
                  isSearchable
                />
              ) : null}

              <label
                className={`col-form-label${errorFields.includes('active') ? ' text-danger' : ''}`}
                htmlFor='active'
                hidden={!canSeeFullTableData}
              >
                Active *
              </label>
              <input
                className={`form-control${errorFields.includes('active') ? ' is-invalid' : ''}`}
                id='active'
                type='text'
                ref={this.activeRef}
                hidden={!canSeeFullTableData}
              />

              <label className='col-form-label' htmlFor='premium' hidden={!canSeeFullTableData}>
                Premium
              </label>
              <input
                className='form-check-input mx-0 my-auto'
                id='premium'
                type='checkbox'
                ref={this.premiumRef}
                disabled={!canSeeFullTableData}
                hidden={!canSeeFullTableData}
              />

              <label className='col-form-label' htmlFor='intro'>
                Intro (Raw HTML)
              </label>
              <textarea className='form-control' id='intro' rows='4' ref={this.introRef} />

              <label className={`col-form-label${errorFields.includes('title') ? ' text-danger' : ''}`} htmlFor='title'>
                Title *
              </label>
              <input
                className={`form-control${errorFields.includes('title') ? ' is-invalid' : ''}`}
                id='title'
                type='text'
                ref={this.titleRef}
              />

              <label className={`col-form-label${errorFields.includes('start') ? ' text-danger' : ''}`} htmlFor='start'>
                Start Date *
              </label>
              <input
                className={`form-control${errorFields.includes('start') ? ' is-invalid' : ''}`}
                id='start'
                type='text'
                ref={this.startRef}
              />

              <label
                className={`col-form-label${errorFields.includes('end') ? ' text-danger' : ''}`}
                htmlFor='end'
                hidden={!canSeeFullTableData}
              >
                End Date *
              </label>
              <input
                className={`form-control${errorFields.includes('end') ? ' is-invalid' : ''}`}
                id='end'
                type='text'
                ref={this.endRef}
                hidden={!canSeeFullTableData}
              />

              <label className='col-form-label' htmlFor='sponsorBannerImageUrl'>
                Sponsor Banner
                <br />
                Image URL
              </label>
              <input
                className='form-control mx-0 my-auto'
                id='sponsorBannerImageUrl'
                type='url'
                ref={this.sponsorBannerImageUrlRef}
              />

              <label className='col-form-label' htmlFor='sponsorBannerLinkUrl'>
                Sponsor Banner
                <br />
                Link URL
              </label>
              <input
                className='form-control mx-0 my-auto'
                id='sponsorBannerLinkUrl'
                type='url'
                ref={this.sponsorBannerLinkUrlRef}
              />

              <label className='col-form-label' htmlFor='customLogoUrl'>
                Custom Logo URL
              </label>
              <input className='form-control' id='customLogoUrl' type='url' ref={this.customLogoUrlRef} />

              <label className='col-form-label' htmlFor='customBannerUrl'>
                Custom Banner URL
              </label>
              <input className='form-control' id='customBannerUrl' type='url' ref={this.customBannerUrlRef} />

              <label
                className={`col-form-label${errorFields.includes('primary_color') ? ' text-danger' : ''}`}
                htmlFor='primaryColor'
              >
                Primary Color *
              </label>
              <input
                className={`form-control${errorFields.includes('primary_color') ? ' is-invalid' : ''}`}
                id='primaryColor'
                type='text'
                ref={this.primaryColorRef}
              />

              <label
                className={`col-form-label${errorFields.includes('secondary_color') ? ' text-danger' : ''}`}
                htmlFor='secondaryColor'
              >
                Secondary Color *
              </label>
              <input
                className={`form-control${errorFields.includes('secondary_color') ? ' is-invalid' : ''}`}
                id='secondaryColor'
                type='text'
                ref={this.secondaryColorRef}
              />

              <label className='col-form-label' htmlFor='calculateBounds' hidden={!canSeeFullTableData}>
                Get bounds from
                <br />
                valid beacons?
              </label>
              <input
                className='form-check-input mx-0 my-auto'
                id='calculateBounds'
                type='checkbox'
                defaultChecked
                ref={this.calculateBoundsRef}
                hidden={!canSeeFullTableData}
              />

              <label className='col-form-label' htmlFor='bounds' hidden={!canSeeFullTableData}>
                World Boundaries
              </label>
              <input
                className='form-control'
                id='bounds'
                type='text'
                ref={this.boundsRef}
                hidden={!canSeeFullTableData}
              />

              <label className='col-form-label' htmlFor='beacons'>
                Beacons
              </label>
              <Select
                styles={{
                  control: base =>
                    errorFields.includes('userId') ? { ...base, borderColor: errorColor } : { ...base },
                  option: (styles, { data }) => {
                    return {
                      ...styles,
                      backgroundColor: data.label.includes('(expired)') ? '#FFEEEE' : styles.backgroundColor,
                    };
                  },
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      backgroundColor: data.label.includes('(expired)') ? '#FFBBBB' : styles.backgroundColor,
                    };
                  },
                }}
                id='beacons'
                isMulti
                onChange={this.handleBeaconListChange}
                value={beaconsSelected}
                size='10'
                options={this.getBeaconOptions()}
              />
            </div>
            <div className='grid' style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <button className='btn btn-primary btn-fancy fw py-2 px-4' type='submit'>
                SAVE
              </button>
              <Link to='/admin/worlds'>
                <button className='btn btn-danger btn-fancy fw py-2 px-4' type='button'>
                  BACK
                </button>
              </Link>
            </div>
          </form>
        </>
      ); // end return
    }
  }
}

export default WorldsFields;
