import { API_URL } from '../config';

/**
 * This helper is used to make all calls to the api.
 * Eg. const allWorlds = apiRequest('GET', 'super-worlds');
 * Eg. apiRequest('POST', 'super-beacons/create', {title: "something", custom_link: "something else"});
 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 * @param {string} requestMethod - GET, PUT, POST, DELETE
 * @param {string} apiRoute - The route we want to call on the API. For instance, if we want to call www.whatevertheapiis.com/login: We'd pass 'login'.
 * @param {json} data - Optional: The data to send to the api. It is up to the api to use this information. use snakecase for this data Eg. {username: "something", hashed_password: "something else"}
 * @returns {json} data or error - This returns data if successful, or an error to display.
 */
export const apiRequest = async (requestMethod, apiRoute, data = null, multiForm = false) => {
  try {
    const requestOptions = {
      method: requestMethod.toUpperCase(),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    if ((requestMethod.toUpperCase() === 'POST' || requestMethod.toUpperCase() === 'PUT') && data) {
      if (multiForm) {
        let fileType = '';
        for (const pair of data.entries()) {
          if (pair[0] === 'uploadFile') {
            fileType = pair[1].type;
          }
        }
        requestOptions.headers = {
          filetype: fileType,
        };
        requestOptions.body = data;
      } else {
        requestOptions.body = JSON.stringify(data);
      }
    }
    if (window.localStorage.getItem('token')) {
      // do not send null tokens
      requestOptions.headers.token = window.localStorage.getItem('token');
    }
    return await fetch(`${API_URL}/admin/${apiRoute}`, requestOptions)
      .then(res => res.json())
      .then(
        response => {
          // if we use res.json() on the api it will return whatever is passed as data
          // res.json({error: "something"}); for instance gives us response.error = "something"
          if (response.expired && window.location.pathname !== '/reset-password') {
            window.location.href = '/reset-password';
            return;
          }
          if (response.invalid) {
            // Token was invalid, clear token, return to login.
            window.localStorage.setItem('token', null);
            window.location.href = '/';
            return;
          }
          if (response.error) {
            // log the error, but also pass it forward, this way we can diagnose fast
            // and choose when we use this error or not for display
            console.error(response.error);
          }
          return response;
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          console.error(error);
          return error;
        },
      );
  } catch (error) {
    console.error(error);
  }
};
