// nav icons
import navAttachmentsSVG from './img/icons/nav-attachments.svg';
import navAnalyticsSVG from './img/icons/nav-analytics.svg';
import navBeaconCategoriesSVG from './img/icons/nav-beacon-categories.svg';
import navBeaconTypesSVG from './img/icons/nav-beacon-types.svg';
import navGamesTypesSVG from './img/icons/nav-games-types.svg';
import navSpacesSVG from './img/icons/nav-spaces.svg';
import navUsersSVG from './img/icons/nav-users.svg';
import navBeaconsSVG from './img/icons/nav-beacons.svg';
import navGamesSVG from './img/icons/nav-games.svg';
import navLogoutSVG from './img/icons/nav-logout.svg';
import navMissingSVG from './img/icons/nav-missing.svg';
import navWorldsSVG from './img/icons/nav-worlds.svg';

// attachment cons
import attachment360 from './img/icons/attachment-360.svg';
import attachmentAudio from './img/icons/attachment-audio.svg';
import attachmentEmail from './img/icons/attachment-email.svg';
import attachmentImage from './img/icons/attachment-image.svg';
import attachmentLink from './img/icons/attachment-link.svg';
import attachmentPhone from './img/icons/attachment-phone.svg';
import attachmentVideo from './img/icons/attachment-video.svg';

export const NAV_ICONS = {
  attachments: navAttachmentsSVG,
  beacons: navBeaconsSVG,
  games: navGamesSVG,
  logout: navLogoutSVG,
  missing: navMissingSVG,
  worlds: navWorldsSVG,
  analytics: navAnalyticsSVG,
  beaconCategories: navBeaconCategoriesSVG,
  beaconTypes: navBeaconTypesSVG,
  gamesTypes: navGamesTypesSVG,
  spaces: navSpacesSVG,
  users: navUsersSVG,
};

export const ATTACHMENT_ICONS = {
  360: attachment360,
  audio: attachmentAudio,
  email: attachmentEmail,
  image: attachmentImage,
  phone: attachmentPhone,
  video: attachmentVideo,
  website: attachmentLink,
};

export const DEFAULT_ZOOM = 18.5;
export const DEFAULT_ZOOM_LARGE = 5;

// TODO: put in the database so we don't have to do this.
export const BEACON_COLORS = {
  food: '#EE3452', // red
  activities: '#8C00BE', // purple
  shopping: '#09C995', // green
  services: '#005ACF', // blue
  default: '#404040', // grey
};
