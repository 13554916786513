import React, { useContext } from 'react';
import { StateContext } from './StateProvider';

const IconPicker = props => {
  const { beaconIcons } = useContext(StateContext); // Essentially replaces Redux - GL
  const { value, onChange } = props;

  const beaconIconGrid = () => {
    const returnIcons = [];
    if (beaconIcons.length > 0) {
      for (const icon of beaconIcons) {
        const iconName = icon.file_name.replace('icons-beacons-', '');
        returnIcons.push(
          <label htmlFor={`check-${iconName}`} className='icon-card' key={iconName}>
            <img src={icon.iconImage} alt={iconName} />
            <input
              id={`check-${iconName}`}
              type='checkbox'
              checked={value.includes(icon.id)}
              value={icon.id}
              onChange={handleCheckChange}
            />
          </label>,
        );
      }
    }
    return returnIcons;
  };

  const handleCheckChange = e => {
    let outputIcons = [...value];

    const iconValue = Number.parseInt(e.target.value);
    const iconChecked = e.target.checked;

    if (iconChecked) {
      if (!outputIcons.includes(iconValue)) {
        outputIcons.push(iconValue);
      }
    } else if (outputIcons.includes(iconValue)) {
      outputIcons = outputIcons.filter(icon => icon !== iconValue);
    }

    onChange(outputIcons);
  };

  return (
    <div className='grid' style={{ gridTemplateColumns: 'repeat(10, 1fr)', gridColumn: '1/-1' }}>
      {beaconIconGrid()}
    </div>
  );
};

export default IconPicker;
