import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import { getValidationErrorMessage, getErrorFields } from '../../helpers/ErrorHelpers';
import Loading from '../Loading';
import { shortRouteDelay } from '../../globals';

const BeaconIconsFields = props => {
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorFields, setErrorFields] = useState([]);
  const [iconFileName, setIconFileName] = useState('');

  const debounceReturn = debounce(() => {
    window.location.href = '/admin/beacon-icons';
  }, shortRouteDelay);

  useEffect(() => {
    const { match } = props;
    const { id } = match.params;
    if (!id) {
      setLoading(false);
      setIsEditing(false);
    } else {
      getType(id);
    }
  }, []); // setting this to empty array makes it only fire ever once - GL

  const getType = async id => {
    try {
      const results = await apiRequest('GET', `beacon-icons/${id}`);
      setLoading(false);
      setIsEditing(true);
      if (results.data) {
        setIconFileName(results.data.file_name);
      }
    } catch (error) {
      displayErrorMessage(error, 'Something went wrong. Please refresh and try again.');
    }
  };

  const displayErrorMessage = (error, message) => {
    setErrorMessage(message);
    setSuccessMessage('');
    console.error(error);
    window.scrollTo(0, 0);
  };

  const displaySuccessMessage = message => {
    setSuccessMessage(message);
    setErrorMessage('');
    window.scrollTo(0, 0);
  };

  const submitForm = async event => {
    const { match } = props;
    try {
      event.preventDefault();
      const requestBody = {
        fileName: iconFileName,
      };

      let data = null;
      if (isEditing) {
        data = await apiRequest('PUT', `beacon-icons/${match.params.id}`, requestBody);
      } else {
        data = await apiRequest('POST', 'beacon-icons', requestBody);
      }

      if (!data) {
        throw new Error('request failed');
      } else if (data.error) {
        displayErrorMessage(data.error, getValidationErrorMessage(data.error));
        setErrorFields(getErrorFields(data.error));
      } else {
        displaySuccessMessage('Type Updated!');
        debounceReturn();
      }
    } catch (error) {
      displayErrorMessage(error, 'Something went wrong. Please refresh and try again.');
    }
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <div className='my-5'>
          <h2>{isEditing ? 'EDIT' : 'CREATE'} BEACON ICON</h2>
        </div>
        <form className='card p-4 fw' onSubmit={submitForm}>
          {errorMessage ? <p className='alert alert-danger --open'>{errorMessage}</p> : null}
          {successMessage ? (
            <>
              <p className='mb-0 alert alert-success --bar '>{successMessage}</p>
              <div className='bar-fill mb-3 --quick' />
            </>
          ) : null}
          <div className='grid mb-4' style={{ gridTemplateColumns: 'auto 1fr' }}>
            <label className={`col-form-label${errorFields.includes('name') ? ' text-danger' : ''}`} htmlFor='name'>
              Icon File Name *
            </label>
            <input
              className={`form-control${errorFields.includes('name') ? ' is-invalid' : ''}`}
              id='name'
              type='text'
              value={iconFileName}
              onChange={e => setIconFileName(e.target.value)}
            />
          </div>
          <div className='grid' style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
            <button className='btn btn-primary btn-fancy fw py-2 px-4' type='submit'>
              SAVE
            </button>
            <Link to='/admin/beacon-icons'>
              <button className='btn btn-danger btn-fancy fw py-2 px-4' type='button'>
                BACK
              </button>
            </Link>
          </div>
        </form>
      </>
    ); // end return
  }
};

export default BeaconIconsFields;
