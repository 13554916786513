import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { apiRequest } from '../../helpers/AjaxHelpers';
import { getValidationErrorMessage, getErrorFields } from '../../helpers/ErrorHelpers';
import { getFullTableDataVisibility } from '../../helpers/VisibilityHelpers';
import { shortRouteDelay, errorColor } from '../../globals';
import Loading from '../Loading';

class GamesFields extends Component {
  constructor(props) {
    super(props);
    const canSeeFullTableData = getFullTableDataVisibility();
    this.state = {
      loading: true,
      isEditing: false,
      game: null,
      objectiveSelected: 'visitIndividual',
      rewardTypeSelected: 'badge',
      worldSelected: null,
      beaconListSelected: [],
      beaconSelected: null,
      objectiveValueLabel: 'Beacon UUID',
      gamesTypes: null,
      worlds: [],
      beacons: [],
      errorMessage: '',
      successMessage: '',
      messageState: '--open',
      errorFields: [],
      canSeeFullTableData,
      beaconRoute: canSeeFullTableData ? 'super-beacons' : 'enterprise-beacons',
      worldRoute: canSeeFullTableData ? 'super-worlds' : 'enterprise-worlds',
      typeOptions: [
        { value: 'badge', label: 'Badge' },
        { value: 'coupon', label: 'Coupon' },
      ],
      gamesRoute: canSeeFullTableData ? 'super-games' : 'enterprise-games',
    };
    this.gametitleRef = React.createRef();
    this.gameDescriptionRef = React.createRef();
    this.worldIdRef = React.createRef();
    this.objectiveValueRef = React.createRef();
    this.rewardTitleRef = React.createRef();
    this.rewardDescriptionRef = React.createRef();
    this.rewardImageRef = React.createRef();
    this.rewardPointRef = React.createRef();
    this.rewardExpirationRef = React.createRef();
    this.rewardHintTitleRef = React.createRef();
    this.rewardHintDescriptionRef = React.createRef();
    this.debounceReturn = debounce(() => {
      window.location.href = '/admin/games';
    }, shortRouteDelay);
  }

  componentDidMount() {
    this.setupPage();
  }

  setupPage = async () => {
    try {
      let isEditing = false;
      const { match } = this.props;
      const { id } = match.params;
      await Promise.all([this.getGameTypes(), this.getBeacons(), this.getWorlds()]);
      if (id) {
        isEditing = true;
        await this.getGame(id);
      }
      this.setState({ loading: false, isEditing });
      this.populateGameFields();
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  getGame = async gamesUuid => {
    try {
      const { gamesRoute } = this.state;
      const results = await apiRequest('GET', `${gamesRoute}/${gamesUuid}`);
      if (results.data) {
        this.setState({
          game: results.data,
        });
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  getBeacons = async () => {
    try {
      const { beaconRoute } = this.state;
      const results = await apiRequest('GET', beaconRoute);
      if (results.data) {
        this.setState({
          beacons: results.data,
        });
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  getWorlds = async () => {
    try {
      const { worldRoute } = this.state;
      const results = await apiRequest('GET', worldRoute);
      if (results.data) {
        this.setState({ worlds: results.data });
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  populateGameFields = () => {
    const { game, worlds, gamesTypes, typeOptions } = this.state;
    if (game !== null) {
      const filteredWorld = worlds.find(world => {
        return world.id === game.group_id;
      });
      const worldSelected = { value: filteredWorld.id, label: filteredWorld.title };

      const filteredObjective = gamesTypes.find(type => {
        return type.name === game.objective;
      });
      const objectiveSelected = { value: filteredObjective.name, label: filteredObjective.display_name };

      const rewardTypeSelected = typeOptions.find(type => {
        return type.value === game.reward_type;
      });

      this.processGameObjectiveValue(game.objective, game.objective_value);

      this.gametitleRef.current.value = game.game_title;
      this.gameDescriptionRef.current.value = game.game_description;
      this.rewardTitleRef.current.value = game.reward_title;
      this.rewardDescriptionRef.current.value = game.reward_description;
      this.rewardImageRef.current.value = game.reward_image_url;
      this.rewardPointRef.current.value = game.reward_point_value;
      this.rewardExpirationRef.current.value = game.reward_expiration;
      this.rewardHintTitleRef.current.value = game.reward_hint_title;
      this.rewardHintDescriptionRef.current.value = game.reward_hint_description;
      this.setState({ objectiveSelected, rewardTypeSelected, worldSelected });
    }
    this.handleObjectiveLabelChange(game?.objective);
  };

  processGameObjectiveValue = (objectiveType, objectiveValue) => {
    const { beacons } = this.state;
    const filteredBeacon = beacons.find(beacon => {
      return beacon.uuid === objectiveValue;
    });
    const beaconSelected = { value: filteredBeacon?.uuid, label: filteredBeacon?.title };

    const beaconListSelected = [];
    const objectiveList = objectiveValue.split(',');
    for (const beacon of beacons) {
      if (objectiveList.includes(beacon.uuid)) {
        beaconListSelected.push({ value: beacon.uuid, label: beacon.title });
      }
    }
    switch (objectiveType) {
      case 'visitIndividual':
        this.setState({ beaconSelected });
        break;
      case 'visitSet':
        this.setState({ beaconListSelected });
        break;
      case 'visitXAny':
        this.objectiveValueRef.current.value = objectiveValue;
        break;
      case 'visitXTimeLimit': // unused
        this.objectiveValueRef.current.value = objectiveValue;
        break;
      case 'visitXCategory': // unused
        this.objectiveValueRef.current.value = objectiveValue;
        break;
      case 'visitXType': // unused
        this.objectiveValueRef.current.value = objectiveValue;
        break;
      default:
        this.setState({ beaconSelected });
    }
  };

  handleObjectiveLabelChange = objectiveType => {
    switch (objectiveType) {
      case 'visitIndividual':
        this.setState({ objectiveValueLabel: 'Beacon' });
        break;
      case 'visitSet':
        this.setState({ objectiveValueLabel: 'Set of Beacons' });
        break;
      case 'visitXAny':
        this.setState({ objectiveValueLabel: 'Number of Beacons' });
        break;
      case 'visitXTimeLimit': // unused
        this.setState({ objectiveValueLabel: 'Number of Beacons in time limit' });
        break;
      case 'visitXCategory': // unused
        this.setState({ objectiveValueLabel: 'Number of Beacons of category' });
        break;
      case 'visitXType': // unused
        this.setState({ objectiveValueLabel: 'Number of Beacons of type' });
        break;
      default:
        this.setState({ objectiveValueLabel: 'Beacon' });
    }
  };

  handleObjectiveChange = e => {
    const label = e.value;
    this.handleObjectiveLabelChange(label);
    this.setState({ objectiveSelected: e });
  };

  handleRewardTypeChange = e => {
    this.setState({ rewardTypeSelected: e });
  };

  handleWorldsChange = e => {
    this.setState({ worldSelected: e });
  };

  handleBeaconChange = e => {
    this.setState({ beaconSelected: e });
  };

  handleBeaconListChange = e => {
    this.setState({ beaconListSelected: e });
  };

  getGameTypes = async () => {
    try {
      const results = await apiRequest('GET', `games-types/enabled`);
      if (results.data) {
        this.setState({ gamesTypes: results.data });
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  getGamesTypesOptions = () => {
    const { gamesTypes } = this.state;
    const returnGamesTypes = [];
    if (gamesTypes) {
      for (const gameType of gamesTypes) {
        returnGamesTypes.push({ value: gameType.name, label: gameType.display_name });
      }
    }
    return returnGamesTypes;
  };

  getBeaconOptions = () => {
    const { beacons } = this.state;
    const returnBeacons = [];
    if (beacons) {
      for (const beacon of beacons) {
        returnBeacons.push({ value: beacon.uuid, label: beacon.title });
      }
    }
    return returnBeacons;
  };

  getWorldsOptions = () => {
    const { worlds } = this.state;
    const returnWorldOptions = [];
    if (worlds) {
      for (const world of worlds) {
        returnWorldOptions.push({ value: world.id, label: world.title });
      }
    }
    return returnWorldOptions;
  };

  submitForm = async event => {
    try {
      const { objectiveSelected, rewardTypeSelected, worldSelected, isEditing, gamesRoute } = this.state;
      const { match } = this.props;
      event.preventDefault();
      const objectiveValueOutput = this.processObjectiveValueOutput();
      const requestBody = {
        title: this.gametitleRef.current.value,
        gameDescription: this.gameDescriptionRef.current.value,
        worldId: worldSelected?.value,
        objective: objectiveSelected?.value,
        objectiveValue: objectiveValueOutput,
        rewardTitle: this.rewardTitleRef.current.value,
        rewardType: rewardTypeSelected?.value,
        rewardDescription: this.rewardDescriptionRef.current.value,
        rewardImage: this.rewardImageRef.current.value,
        rewardPoint: this.rewardPointRef.current.value,
        rewardExpiration: this.rewardExpirationRef.current.value,
        rewardHintTitle: this.rewardHintTitleRef.current.value,
        rewardHintDescription: this.rewardHintDescriptionRef.current.value,
      };

      let data = null;
      if (isEditing) {
        data = await apiRequest('PUT', `${gamesRoute}/${match.params.id}`, requestBody);
      } else {
        data = await apiRequest('POST', gamesRoute, requestBody);
      }

      // scroll after request
      window.scrollTo(0, 0);

      if (!data) {
        throw new Error('request failed');
      } else if (data.error) {
        this.setState({
          errorMessage: getValidationErrorMessage(data.error),
          errorFields: getErrorFields(data.error),
          messageState: '--open',
        });
      } else {
        this.setState({ errorMessage: '', successMessage: 'Game Updated!' });
        this.debounceReturn();
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  processObjectiveValueOutput = () => {
    const { objectiveSelected, beaconSelected, beaconListSelected } = this.state;
    let output = null;
    const beaconUuidList = [];
    switch (objectiveSelected.value) {
      case 'visitIndividual':
        output = beaconSelected.value;
        break;
      case 'visitSet':
        for (const selection of beaconListSelected) {
          beaconUuidList.push(selection.value);
        }
        output = beaconUuidList.join(',');
        break;
      case 'visitXAny':
        output = this.objectiveValueRef.current.value;
        break;
      case 'visitXTimeLimit': // unused
        output = this.objectiveValueRef.current.value;
        break;
      case 'visitXCategory': // unused
        output = this.objectiveValueRef.current.value;
        break;
      case 'visitXType': // unused
        output = this.objectiveValueRef.current.value;
        break;
      default:
        output = this.objectiveValueRef.current.value;
    }
    return output;
  };

  determineObjectiveInput = () => {
    const { objectiveSelected } = this.state;
    let output = 'number';
    switch (objectiveSelected.value) {
      case 'visitIndividual':
        output = 'select';
        break;
      case 'visitSet':
        output = 'select-list';
        break;
      case 'visitXAny':
        output = 'number';
        break;
      case 'visitXTimeLimit': // unused
        output = 'number';
        break;
      case 'visitXCategory': // unused
        output = 'number';
        break;
      case 'visitXType': // unused
        output = 'number';
        break;
      default:
        output = 'select';
    }
    return output;
  };

  render() {
    const {
      loading,
      isEditing,
      errorMessage,
      messageState,
      successMessage,
      objectiveSelected,
      rewardTypeSelected,
      objectiveValueLabel,
      errorFields,
      canSeeFullTableData,
      worldSelected,
      typeOptions,
      beaconListSelected,
      beaconSelected,
    } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          <div className='my-5'>
            <h2>{isEditing ? 'EDIT' : 'CREATE'} GAME</h2>
          </div>
          <form className='card p-4 fw' onSubmit={this.submitForm}>
            {errorMessage ? <p className={`alert alert-danger ${messageState}`}>{errorMessage}</p> : null}
            {successMessage ? (
              <>
                <p className='mb-0 alert alert-success --bar '>{successMessage}</p>
                <div className='bar-fill mb-3 --quick' />
              </>
            ) : null}
            <div className='grid mb-4' style={{ gridTemplateColumns: 'auto 1fr' }}>
              <label className={`col-form-label${errorFields.includes('title') ? ' text-danger' : ''}`} htmlFor='title'>
                Game Title *
              </label>
              <input
                className={`form-control${errorFields.includes('title') ? ' is-invalid' : ''}`}
                id='title'
                type='text'
                ref={this.gametitleRef}
              />
              <label
                className={`col-form-label${errorFields.includes('gameDescription') ? ' text-danger' : ''}`}
                htmlFor='gameDescription'
              >
                Game Description *
              </label>
              <input
                className={`form-control${errorFields.includes('gameDescription') ? ' is-invalid' : ''}`}
                id='gameDescription'
                type='text'
                ref={this.gameDescriptionRef}
              />

              <label
                className={`col-form-label${errorFields.includes('worldId') ? ' text-danger' : ''}`}
                htmlFor='worldId'
              >
                World *
              </label>
              <Select
                id='worldId'
                styles={{
                  control: base =>
                    errorFields.includes('worldId') ? { ...base, borderColor: errorColor } : { ...base },
                }}
                value={worldSelected}
                onChange={this.handleWorldsChange}
                options={this.getWorldsOptions()}
                isSearchable
              />

              <label className='col-form-label' htmlFor='objective'>
                Game Objective
              </label>
              <Select
                id='objective'
                value={objectiveSelected}
                onChange={this.handleObjectiveChange}
                options={this.getGamesTypesOptions()}
              />

              <label
                className={`col-form-label${errorFields.includes('objectiveValue') ? ' text-danger' : ''}`}
                htmlFor='objectiveValue'
                id='objectiveValue_label'
              >
                {objectiveValueLabel} *
              </label>
              <input
                className={`form-control${errorFields.includes('objectiveValue') ? ' is-invalid' : ''}`}
                id='objectiveValue'
                type='number'
                ref={this.objectiveValueRef}
                hidden={this.determineObjectiveInput() !== 'number'}
              />
              {this.determineObjectiveInput() === 'select' ? (
                <Select
                  styles={{
                    control: base =>
                      errorFields.includes('objectiveValue') ? { ...base, borderColor: errorColor } : { ...base },
                  }}
                  id='objectiveValue'
                  onChange={this.handleBeaconChange}
                  value={beaconSelected}
                  size='10'
                  options={this.getBeaconOptions()}
                />
              ) : null}
              {this.determineObjectiveInput() === 'select-list' ? (
                <Select
                  styles={{
                    control: base =>
                      errorFields.includes('objectiveValue') ? { ...base, borderColor: errorColor } : { ...base },
                  }}
                  id='objectiveValue'
                  isMulti
                  onChange={this.handleBeaconListChange}
                  value={beaconListSelected}
                  size='10'
                  options={this.getBeaconOptions()}
                />
              ) : null}

              <label
                className={`col-form-label${errorFields.includes('rewardTitle') ? ' text-danger' : ''}`}
                htmlFor='rewardTitle'
              >
                Reward Title *
              </label>
              <input
                className={`form-control${errorFields.includes('rewardTitle') ? ' is-invalid' : ''}`}
                id='rewardTitle'
                type='text'
                ref={this.rewardTitleRef}
              />
              <label className='col-form-label' htmlFor='rewardType'>
                Reward Type
              </label>
              <Select
                id='rewardType'
                type='checkbox'
                value={rewardTypeSelected}
                onChange={this.handleRewardTypeChange}
                options={typeOptions}
              />
              <label
                className={`col-form-label${errorFields.includes('rewardDescription') ? ' text-danger' : ''}`}
                htmlFor='rewardDescription'
              >
                Reward Description *
              </label>
              <input
                className={`form-control${errorFields.includes('rewardDescription') ? ' is-invalid' : ''}`}
                id='rewardDescription'
                type='text'
                ref={this.rewardDescriptionRef}
              />
              <label
                className={`col-form-label${errorFields.includes('rewardImage') ? ' text-danger' : ''}`}
                htmlFor='rewardImage'
              >
                Reward Image *
              </label>
              <input
                className={`form-control${errorFields.includes('rewardImage') ? ' is-invalid' : ''}`}
                id='rewardImage'
                type='text'
                ref={this.rewardImageRef}
              />
              <label className='col-form-label' htmlFor='rewardPoint' hidden={!canSeeFullTableData}>
                Reward Points
              </label>
              <input
                className='form-control'
                id='rewardPoint'
                type='text'
                ref={this.rewardPointRef}
                hidden={!canSeeFullTableData}
              />
              <label className='col-form-label' htmlFor='rewardExpiration'>
                Reward Expiration
                <br />
                (in seconds)
              </label>
              <input
                className='form-control mx-0 my-auto'
                id='rewardExpiration'
                type='number'
                ref={this.rewardExpirationRef}
              />
              <label className='col-form-label' htmlFor='rewardHintTitle'>
                Reward Hint Title
              </label>
              <input className='form-control' id='rewardHintTitle' type='text' ref={this.rewardHintTitleRef} />
              <label className='col-form-label' htmlFor='rewardHintDescription'>
                Reward Hint Description
              </label>
              <input
                className='form-control'
                id='rewardHintDescription'
                type='text'
                ref={this.rewardHintDescriptionRef}
              />
            </div>
            <div className='grid' style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <button className='btn btn-primary btn-fancy fw py-2 px-4' type='submit'>
                SAVE
              </button>
              <Link to='/admin/games'>
                <button className='btn btn-danger btn-fancy fw py-2 px-4' type='button'>
                  BACK
                </button>
              </Link>
            </div>
          </form>
        </>
      ); // end return
    }
  }
}

export default GamesFields;
