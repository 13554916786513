import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import Loading from '../Loading';

class RoomsFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      room: {},
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.getRoom(id);
    }
  }

  getRoom = async id => {
    try {
      const results = await apiRequest('GET', `rooms/${id}`);
      if (results.data) {
        this.setState({ room: results.data, loading: false });
      }
    } catch (error) {
      console.error(error);
    }
  };

  submitForm = async event => {
    try {
      const { match, history } = this.props;
      event.preventDefault();
      window.scrollTo(0, 0);
      const requestBody = {};
      await apiRequest('PUT', `rooms/${match.params.id}/edit`, requestBody);
      history.push('/admin/rooms');
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { loading, room } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          <div className='my-5'>
            <h2>EDIT SPACE</h2>
          </div>
          <form className='card p-4 fw' onSubmit={this.submitForm}>
            <div className='grid mb-4' style={{ gridTemplateColumns: 'auto 1fr' }}>
              <span className='col-form-label'>ID</span>
              <span className='col-form-label'>{room.id}</span>
              <span className='col-form-label'>Created</span>
              <span className='col-form-label'>{room.created_at}</span>
              <span className='col-form-label'>Updated</span>
              <span className='col-form-label'>{room.updated_at}</span>
              <span className='col-form-label'>Deleted</span>
              <span className='col-form-label'>{room.deleted_at || 'null'}</span>
              <span className='col-form-label'>Active</span>
              <span className='col-form-label'>{room.active}</span>
              <span className='col-form-label'>Creator</span>
              <span className='col-form-label'>{room.room_creator_uuid}</span>
              <span className='col-form-label'>URL</span>
              <span className='col-form-label'>{room.url}</span>
            </div>
            <div className='grid' style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <Link to='/admin/rooms'>
                <button className='btn btn-danger btn-fancy fw py-2 px-4' type='button'>
                  BACK
                </button>
              </Link>
            </div>
          </form>
        </>
      ); // end return
    }
  }
}

export default RoomsFields;
