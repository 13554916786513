import React, { useState, useEffect, useCallback } from 'react';
import { apiRequest } from '../helpers/AjaxHelpers';

/* Functionally replaces Redux using context
    This will wrap the Admin router (after being logged in) so logging in can fire getting all the data we want, one time.
    This will let us cache data, and share state in a simple object.
     - GL
*/
const StateProvider = ({ subPages }) => {
  const [beaconIcons, setBeaconIcons] = useState([]);
  const [beaconIconsMap, setBeaconIconsMap] = useState({});
  const [eventTrigger, setEventTrigger] = useState(0);

  useEffect(() => {
    getIcons().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventTrigger]); // setting this to empty array makes it only fire ever once - GL

  const getIcons = useCallback(async () => {
    try {
      const results = await apiRequest('GET', 'beacon-icons');
      if (results.data) {
        const iconData = [...results.data];
        const iconMap = {};
        for (const icon of iconData) {
          // eslint-disable-next-line import/no-dynamic-require, global-require
          try {
            icon.iconImage = require(`../img/beacons/svg/${icon.file_name}.svg`);
          } catch {
            icon.iconImage = null;
          }
          iconMap[icon.id] = icon;
        }
        setBeaconIcons(iconData);
        setBeaconIconsMap(iconMap);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  // Anything we want to expose to the subpages must be in the value array. - GL
  return (
    <StateContext.Provider value={{ beaconIcons, beaconIconsMap, setEventTrigger }}>{subPages}</StateContext.Provider>
  );
};

export default StateProvider;
export const StateContext = React.createContext('');
