import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import Loading from '../Loading';

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = { categories: [], loading: true };
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories = async () => {
    try {
      const results = await apiRequest('GET', 'categories');
      let data = [];
      if (results.data) {
        data = results.data;
      }
      this.setState({ categories: data, loading: false });
    } catch (error) {
      console.error(error);
    }
  };

  listCategoriesData = () => {
    const { categories } = this.state;
    const output = [];
    for (const category of categories) {
      output.push(
        <tr key={category.id}>
          <td>{category.name}</td>
          <td>
            <Link to={`/admin/categories/${category.id}`}>
              <button className='btn btn-secondary' type='button'>
                edit
              </button>
            </Link>
          </td>
        </tr>,
      );
    }
    return output;
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          <div className='my-5'>
            <h2>CREATE CATEGORY</h2>
            <Link to='/admin/categories/create'>
              <button className='btn btn-fancy btn-primary py-3 px-4' type='button'>
                Add Category +
              </button>
            </Link>
          </div>
          <div className='mt-5'>
            <h2>MANAGE CATEGORIES</h2>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th style={{ width: '100%' }}>Category</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>{this.listCategoriesData()}</tbody>
            </table>
          </div>
        </>
      );
    }
  }
}

export default Categories;
