import React, { useState } from 'react';
import Select from 'react-select';
import { apiRequest } from '../helpers/AjaxHelpers';
import { getUploadVisibility } from '../helpers/VisibilityHelpers';
import copyIcon from '../img/icons/icon-copy.svg';

const ImageUploader = () => {
  const [statusMessage, setStatusMessage] = useState('Waiting for file to be chosen.');
  const [statusType, setStatusType] = useState('info');
  const [uploadTypeSelected, setUploadTypeSelected] = useState({ value: 'banner', label: 'Banner' });
  const [uploadTypeText, setUploadTypeText] = useState('Image will be resized and cropped to 1500px x 500px');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [fileDisabled, setFileDisabled] = useState(false);
  const [fileSelected, setFileSelected] = useState(null);

  const getUploadTypeOptions = () => {
    const uploadTypeOptions = [
      { value: 'banner', label: 'Banner' },
      { value: 'logo', label: 'Logo' },
      { value: 'attachment', label: 'Attachment' },
    ];
    return uploadTypeOptions;
  };

  const getUploadedFileList = () => {
    const output = [];
    for (const file of uploadedFiles) {
      output.push(
        <>
          <button
            onClick={() => {
              navigator.clipboard.writeText(file.url);
              alert('URL Copied to clipboard');
            }}
            className='my-auto'
            style={{ background: 'none', border: 'none' }}
            key={`${file.name} button`}
            type='button'
          >
            <img src={copyIcon} alt='copy' />
          </button>
          <span className='my-auto truncate-no-width' key={`${file.name} span`}>
            {file.name}
          </span>
        </>,
      );
    }
    return output;
  };

  const uploadToApi = async event => {
    try {
      event.preventDefault();
      setButtonDisabled(true);
      setFileDisabled(true);
      setStatusMessage('Uploading...');
      setStatusType('info');

      const formData = new FormData();

      formData.append('uploadFile', fileSelected);
      formData.append('size', uploadTypeSelected.value);

      const response = await apiRequest('POST', `upload`, formData, true);
      if (response) {
        setUploadedFiles([...uploadedFiles, { name: fileSelected.name, url: response.data }]);
        setFileDisabled(false);
        setStatusMessage('Upload Success!');
        setStatusType('success');
      }
    } catch (error) {
      setStatusMessage('Error occured while uploading. Please refresh and try again.');
      setStatusType('error');
      console.error(error);
    }
  };

  const onFileChange = e => {
    setFileSelected(e.target.files[0]);
    setButtonDisabled(false);
    setStatusMessage('File selected! Click Upload.');
    setStatusType('info');
  };

  const onUploadTypeChange = e => {
    setUploadTypeSelected(e);
    switch (e.value) {
      case 'banner':
        setUploadTypeText('Image will be resized and cropped to 1500px x 500px');
        break;
      case 'logo':
        setUploadTypeText('Image will be resized and cropped to 500px x 500px');
        break;
      case 'attachment':
        setUploadTypeText('Image will be scaled down such that the largest dimension is 1500px ');
        break;
      default:
        setUploadTypeText('Image will be scaled down such that the largest dimension is 1500px ');
        break;
    }
  };

  return getUploadVisibility() ? (
    <>
      <div className='my-5'>
        <h2>UPLOAD FILE</h2>
        <span>You can use this feature to upload an image to our S3 and get a url for use.</span>
      </div>

      <div className='card p-4 fw'>
        <p className={`alert alert-${statusType}`}>{statusMessage}</p>
        <input
          className='form-control'
          id='uploadFile'
          disabled={fileDisabled}
          onChange={onFileChange}
          accept='.avif, .jpg, .jpeg, .webp, .png'
          type='file'
        />
        <br />
        <Select
          id='userId'
          value={uploadTypeSelected}
          onChange={onUploadTypeChange}
          options={getUploadTypeOptions()}
          isSearchable
        />
        <span>{uploadTypeText}</span>
        <br />
        <button
          className='btn btn-primary btn-fancy fw py-2 px-4'
          onClick={uploadToApi}
          disabled={buttonDisabled}
          type='submit'
        >
          UPLOAD
        </button>
        {uploadedFiles.length > 0 ? (
          <>
            <br />
            <div
              className='grid'
              style={{
                gridTemplateColumns: 'auto 1fr',
                columnGap: 0,
              }}
            >
              {getUploadedFileList()}
            </div>
          </>
        ) : null}
      </div>
    </>
  ) : null;
};

export default ImageUploader;
