import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import Loading from '../Loading';
import { getCreateGameVisibility, getFullTableDataVisibility } from '../../helpers/VisibilityHelpers';

class Games extends Component {
  constructor(props) {
    super(props);
    const canCreateGame = getCreateGameVisibility();
    const canSeeFullTableData = getFullTableDataVisibility();
    this.state = {
      games: [],
      loading: true,
      canCreateGame,
      gamesRoute: canSeeFullTableData ? 'super-games' : 'enterprise-games',
    };
  }

  componentDidMount() {
    this.getGames();
  }

  getGames = async () => {
    try {
      const { gamesRoute } = this.state;
      const results = await apiRequest('GET', gamesRoute);
      let data = [];
      if (results.data) {
        data = results.data;
      }
      this.setState({ games: data, loading: false });
    } catch (error) {
      console.error(error);
    }
  };

  listGameData = () => {
    const { games } = this.state;
    const output = [];
    for (const game of games) {
      output.push(
        <tr key={game.game_title}>
          <td>{game.id} </td>
          <td>{game.game_title} </td>
          <td>
            <Link to={`/admin/games/${game.uuid}`}>
              <button className='btn btn-secondary' type='button'>
                edit
              </button>
            </Link>
          </td>
        </tr>,
      );
    }
    return output;
  };

  render() {
    const { loading, canCreateGame } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          {canCreateGame ? (
            <div className='my-5'>
              <h2>CREATE GAME</h2>
              <Link to='/admin/games/create'>
                <button className='btn btn-fancy btn-primary py-3 px-4' type='button'>
                  Add Game +
                </button>
              </Link>
            </div>
          ) : null}
          <div className='mt-5'>
            <h2>MANAGE GAMES</h2>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th style={{ width: '100%' }}>Game</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>{this.listGameData()}</tbody>
            </table>
          </div>
        </>
      );
    }
  }
}

export default Games;
