// This hides the top and bottom sections of the admin menu.
export const getAdminSideNavItemsVisibility = () => {
  const allowedTypes = ['innovatar'];

  const token = JSON.parse(window.localStorage.getItem('token'));
  return allowedTypes.includes(token.userType);
};

// This hides the id's on tables and fields.
export const getFullTableDataVisibility = () => {
  const allowedTypes = ['innovatar'];

  const token = JSON.parse(window.localStorage.getItem('token'));
  return allowedTypes.includes(token.userType);
};

// This hides the create beacon button
export const getCreateBeaconVisibility = () => {
  const allowedTypes = ['innovatar'];

  const token = JSON.parse(window.localStorage.getItem('token'));
  return allowedTypes.includes(token.userType);
};

// This hides the create world button
export const getCreateWorldVisibility = () => {
  const allowedTypes = ['innovatar'];

  const token = JSON.parse(window.localStorage.getItem('token'));
  return allowedTypes.includes(token.userType);
};

// This hides the create game button
export const getCreateGameVisibility = () => {
  const allowedTypes = ['innovatar'];

  const token = JSON.parse(window.localStorage.getItem('token'));
  return allowedTypes.includes(token.userType);
};

// This hides the create attachment button
export const getCreateAttachmentVisibility = () => {
  const allowedTypes = ['innovatar'];

  const token = JSON.parse(window.localStorage.getItem('token'));
  return allowedTypes.includes(token.userType);
};

// This hides the create attachment button
export const getUploadVisibility = () => {
  const allowedTypes = ['innovatar', 'enterprise'];

  const token = JSON.parse(window.localStorage.getItem('token'));
  return allowedTypes.includes(token.userType);
};
