import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { apiRequest } from '../../helpers/AjaxHelpers';
import { getValidationErrorMessage, getErrorFields } from '../../helpers/ErrorHelpers';
import { shortRouteDelay, errorColor } from '../../globals';
import Loading from '../Loading';

class AdminUsersFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      admin: null,
      adminRoles: null,
      adminUserTypes: null,
      isEditing: false,
      roleSelected: '',
      typeSelected: '',
      errorMessage: '',
      successMessage: '',
      messageState: '--open',
      errorFields: [],
    };
    this.emailRef = React.createRef();
    this.usernameRef = React.createRef();
    this.debounceReturn = debounce(() => {
      window.location.href = '/admin/admin-users';
    }, shortRouteDelay);
  }

  componentDidMount() {
    this.setupPage();
  }

  setupPage = async () => {
    try {
      let isEditing = false;
      const { match } = this.props;
      const { id } = match.params;
      await Promise.all([this.getAdminRoles(), this.getAdminUserTypes()]);
      if (id) {
        await this.getAdmin(id);
        isEditing = true;
      }
      this.setState({ loading: false, isEditing });
      this.populateAdminFields();
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  getAdmin = async adminUuid => {
    const results = await apiRequest('GET', `admin-users/${adminUuid}`);
    if (results.data) {
      this.setState({ admin: results.data });
    }
  };

  getAdminRoles = async () => {
    try {
      const results = await apiRequest('GET', `admin-roles`);
      if (results.data) {
        this.setState({ adminRoles: results.data });
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  getAdminUserTypes = async () => {
    try {
      const results = await apiRequest('GET', `admin-user-types`);
      if (results.data) {
        this.setState({ adminUserTypes: results.data });
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  populateAdminFields = () => {
    const { admin, adminUserTypes, adminRoles } = this.state;
    if (admin !== null) {
      let filteredType = {};
      let filteredRole = {};
      filteredType = adminUserTypes.find(type => {
        return type.id === admin.type_id;
      });
      filteredRole = adminRoles.find(role => {
        return role.id === admin.role_id;
      });
      const setType = { value: filteredType.id, label: filteredType.name };
      const setRole = { value: filteredRole.id, label: filteredRole.display_name };
      this.emailRef.current.value = admin.email;
      this.usernameRef.current.value = admin.username;
      this.setState({ roleSelected: setRole, typeSelected: setType });
    }
  };

  submitForm = async event => {
    try {
      event.preventDefault();
      const { roleSelected, isEditing, typeSelected } = this.state;
      const { match } = this.props;
      const requestBody = {
        email: this.emailRef.current.value,
        username: this.usernameRef.current.value,
        roleId: roleSelected?.value,
        typeId: typeSelected?.value,
      };

      let data = null;
      if (isEditing) {
        data = await apiRequest('PUT', `admin-users/${match.params.id}/edit`, requestBody);
      } else {
        data = await apiRequest('POST', 'admin-users/create', requestBody);
      }

      // scroll after request
      window.scrollTo(0, 0);

      if (!data) {
        throw new Error('request failed');
      } else if (data.error) {
        this.setState({
          errorMessage: getValidationErrorMessage(data.error),
          errorFields: getErrorFields(data.error),
          messageState: '--open',
        });
      } else {
        this.setState({ errorMessage: '', successMessage: 'User Updated!' });
        this.debounceReturn();
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  onRoleChanged = e => {
    this.setState({ roleSelected: e });
  };

  onTypeChanged = e => {
    this.setState({ typeSelected: e });
  };

  getRoleOptions = () => {
    const { adminRoles } = this.state;
    const roleOptions = adminRoles.map(role => {
      return { value: role.id, label: role.display_name };
    });
    return roleOptions;
  };

  getTypeOptions = () => {
    const { adminUserTypes } = this.state;
    const typeOptions = adminUserTypes.map(type => {
      return { value: type.id, label: type.name };
    });
    return typeOptions;
  };

  render() {
    const { isEditing, loading, messageState, errorMessage, successMessage, roleSelected, errorFields, typeSelected } =
      this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          <div className='my-5'>
            <h2>{isEditing ? 'EDIT' : 'CREATE'} USER</h2>
          </div>
          <form className='card p-4' onSubmit={this.submitForm}>
            {errorMessage ? <p className={`alert alert-danger ${messageState}`}>{errorMessage}</p> : null}
            {successMessage ? (
              <div>
                <p className='mb-0 alert alert-success --bar '>{successMessage}</p>
                <div className='bar-fill mb-3 --quick' />
              </div>
            ) : null}
            <div className='grid mb-4' style={{ gridTemplateColumns: 'auto 1fr' }}>
              <label className={`col-form-label${errorFields.includes('email') ? ' text-danger' : ''}`} htmlFor='email'>
                Email *
              </label>
              <input
                className={`form-control${errorFields.includes('email') ? ' is-invalid' : ''}`}
                id='email'
                type='email'
                ref={this.emailRef}
              />
              <label
                className={`col-form-label${errorFields.includes('username') ? ' text-danger' : ''}`}
                htmlFor='username'
              >
                Username *
              </label>
              <input
                className={`form-control${errorFields.includes('username') ? ' is-invalid' : ''}`}
                id='username'
                type='text'
                ref={this.usernameRef}
              />
              <label className={`col-form-label${errorFields.includes('roleId') ? ' text-danger' : ''}`} htmlFor='role'>
                Role *
              </label>
              <Select
                styles={{
                  control: base =>
                    errorFields.includes('roleId') ? { ...base, borderColor: errorColor } : { ...base },
                }}
                id='role'
                value={roleSelected}
                onChange={this.onRoleChanged}
                options={this.getRoleOptions()}
              />
              <label className={`col-form-label${errorFields.includes('typeId') ? ' text-danger' : ''}`} htmlFor='type'>
                Type *
              </label>
              <Select
                styles={{
                  control: base =>
                    errorFields.includes('typeId') ? { ...base, borderColor: errorColor } : { ...base },
                }}
                id='type'
                value={typeSelected}
                onChange={this.onTypeChanged}
                options={this.getTypeOptions()}
              />
            </div>
            <div className='grid' style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <button className='btn btn-fancy btn-primary fw py-2 px-4' type='submit'>
                SAVE
              </button>
              <Link to='/admin/admin-users'>
                <button className='btn btn-fancy btn-danger fw py-2 px-4' type='button'>
                  CANCEL
                </button>
              </Link>
            </div>
          </form>
        </>
      ); // end return
    }
  }
}

export default AdminUsersFields;
