import React from 'react';

/**
 * Component for setting the header and content container for the page
 *
 * @param props - {
 *   bodyMaxWidth: The max width of the body
 *   children: react passes this in between <Content> tags
 *   title: The title to show in the header, automatically made uppercase
 * }
 * @returns {JSX.Element}
 */

const Content = props => {
  const { bodyMaxWidth, children, title } = props;
  const bodyStyle = {
    maxWidth: bodyMaxWidth,
  };
  return (
    <div className='container-fluid p-0'>
      <div className='bg-header p-0 mb-5'>
        <h1 className='my-0 mx-5 pl-5 text-white'>{title.toUpperCase()}</h1>
      </div>
      <div className='m-5' style={bodyStyle}>
        {children}
      </div>
    </div>
  );
};

export default Content;
