import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import Select from 'react-select';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import { getValidationErrorMessage, getErrorFields } from '../../helpers/ErrorHelpers';
import { shortRouteDelay, errorColor } from '../../globals';

class GamesTypesFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      gameType: null,
      gamesObjectiveValueTypes: null,
      isEditing: false,
      gamesObjectiveValueTypeSelected: '',
      errorMessage: '',
      successMessage: '',
      messageState: '--open',
      errorFields: [],
    };
    this.nameRef = React.createRef();
    this.displayNameRef = React.createRef();
    this.descriptionRef = React.createRef();
    this.enabledRef = React.createRef();
    this.debounceReturn = debounce(() => {
      window.location.href = '/admin/games-types';
    }, shortRouteDelay);
  }

  componentDidMount() {
    this.setupPage();
  }

  setupPage = async () => {
    try {
      let isEditing = false;
      const { match } = this.props;
      const { id } = match.params;
      if (id) {
        await Promise.all([this.getGamesType(id), this.getGamesObjectiveValueTypes()]);
        isEditing = true;
      } else {
        await this.getGamesObjectiveValueTypes();
      }
      this.setState({ loading: false, isEditing });
      this.populateGamesTypesFields();
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  getGamesType = async gameTypeId => {
    try {
      const results = await apiRequest('GET', `games-types/${gameTypeId}`);
      if (results.data) {
        this.setState({ gameType: results.data });
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  getGamesObjectiveValueTypes = async () => {
    try {
      const results = await apiRequest('GET', `games-objective-value-types`);
      if (results.data) {
        this.setState({ gamesObjectiveValueTypes: results.data });
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  populateGamesTypesFields = () => {
    const { gameType, gamesObjectiveValueTypes } = this.state;
    if (gameType !== null) {
      const filteredObjective = gamesObjectiveValueTypes.find(value => {
        return value.id === gameType.objective_value_type;
      });
      const setObjective = { value: filteredObjective.id, label: filteredObjective.value_types };
      this.nameRef.current.value = gameType.name;
      this.displayNameRef.current.value = gameType.display_name;
      this.descriptionRef.current.value = gameType.description;
      this.enabledRef.current.checked = gameType.enabled;
      this.setState({ gamesObjectiveValueTypeSelected: setObjective });
    }
  };

  submitForm = async event => {
    try {
      const { gamesObjectiveValueTypeSelected, isEditing } = this.state;
      const { match } = this.props;
      event.preventDefault();
      const requestBody = {
        name: this.nameRef.current.value,
        displayName: this.displayNameRef.current.value,
        objectiveValueType: gamesObjectiveValueTypeSelected.value,
        description: this.descriptionRef.current.value,
        enabled: this.enabledRef.current.checked,
      };

      let data = null;
      if (isEditing) {
        data = await apiRequest('PUT', `games-types/${match.params.id}`, requestBody);
      } else {
        data = await apiRequest('POST', 'games-types', requestBody);
      }

      // scroll after request
      window.scrollTo(0, 0);

      if (!data) {
        throw new Error('request failed');
      } else if (data.error) {
        this.setState({
          errorMessage: getValidationErrorMessage(data.error),
          errorFields: getErrorFields(data.error),
          messageState: '--open',
        });
      } else {
        this.setState({ errorMessage: '', successMessage: 'Games Type Updated!' });
        this.debounceReturn();
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  onGamesObjectiveValueTypeChanged = e => {
    this.setState({ gamesObjectiveValueTypeSelected: e });
  };

  getGamesObjectiveValueTypeOptions = () => {
    const { gamesObjectiveValueTypes } = this.state;
    const returnValueTypes = [];
    for (const valueType of gamesObjectiveValueTypes) {
      returnValueTypes.push({ value: valueType.id, label: valueType.value_types });
    }
    return returnValueTypes;
  };

  render() {
    const {
      loading,
      isEditing,
      errorMessage,
      messageState,
      successMessage,
      gamesObjectiveValueTypeSelected,
      errorFields,
    } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          <div className='my-5'>
            <h2>{isEditing ? 'EDIT' : 'CREATE'} GAMES TYPE</h2>
          </div>
          <form className='card p-4 fw' onSubmit={this.submitForm}>
            {errorMessage ? <p className={`alert alert-danger ${messageState}`}>{errorMessage}</p> : null}
            {successMessage ? (
              <>
                <p className='mb-0 alert alert-success --bar '>{successMessage}</p>
                <div className='bar-fill mb-3 --quick' />
              </>
            ) : null}
            <div className='grid mb-4' style={{ gridTemplateColumns: 'auto 1fr' }}>
              <label
                className={`col-sm-2 col-form-label${errorFields.includes('displayName') ? ' text-danger' : ''}`}
                htmlFor='displayName'
              >
                Display Name *
              </label>
              <input
                className={`form-control${errorFields.includes('displayName') ? ' is-invalid' : ''}`}
                id='displayName'
                type='text'
                ref={this.displayNameRef}
              />

              <label
                className={`col-sm-2 col-form-label${errorFields.includes('name') ? ' text-danger' : ''}`}
                htmlFor='name'
              >
                DB Name *
              </label>
              <input
                className={`form-control${errorFields.includes('name') ? ' is-invalid' : ''}`}
                id='name'
                type='text'
                disabled={isEditing}
                ref={this.nameRef}
              />

              <label
                className={`col-sm-2 col-form-label${errorFields.includes('objectiveValueType') ? ' text-danger' : ''}`}
                htmlFor='objectiveValueType'
              >
                Objective Value Types *
              </label>
              <Select
                id='objectiveValueType'
                styles={{
                  control: base =>
                    errorFields.includes('objectiveValueType') ? { ...base, borderColor: errorColor } : { ...base },
                }}
                value={gamesObjectiveValueTypeSelected}
                onChange={this.onGamesObjectiveValueTypeChanged}
                isDisabled={isEditing}
                options={this.getGamesObjectiveValueTypeOptions()}
              />

              <label className='col-sm-2 col-form-label' htmlFor='enabled'>
                Enabled
              </label>
              <input
                className='form-check-input mx-0 my-auto'
                id='enabled'
                type='checkbox'
                defaultChecked
                ref={this.enabledRef}
              />

              <label className='col-sm-2 col-form-label' htmlFor='description'>
                Description
              </label>
              <textarea className='form-control' id='description' rows='4' ref={this.descriptionRef} />
            </div>
            <div className='grid' style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <button className='btn btn-primary btn-fancy fw py-2 px-4' type='submit'>
                SAVE
              </button>
              <Link to='/admin/games-types'>
                <button className='btn btn-danger btn-fancy fw py-2 px-4' type='button'>
                  BACK
                </button>
              </Link>
            </div>
          </form>
        </>
      ); // end return
    }
  }
}

export default GamesTypesFields;
