import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import { getSectionFromRoute } from '../../helpers/RouteHelpers';
import { defaultMaxWidth } from '../../globals';
import Loading from '../Loading';
import StateProvider from '../StateProvider';
import Content from '../Content';
import SideNav from './SideNav';

// Route Content ===============================================================
import Beacons from '../Beacons/Beacons';
import BeaconsFields from '../Beacons/BeaconsFields';
import AdminUsers from '../AdminUsers/AdminUsers';
import AdminUsersFields from '../AdminUsers/AdminUsersFields';
import AdminUserTypes from '../AdminUserTypes/AdminUserTypes';
import AdminUserTypesFields from '../AdminUserTypes/AdminUserTypesFields';
import Worlds from '../Worlds/Worlds';
import WorldsFields from '../Worlds/WorldsFields';
import Games from '../Games/Games';
import GamesFields from '../Games/GamesFields';
import GamesTypes from '../GamesTypes/GamesTypes';
import GamesTypesFields from '../GamesTypes/GamesTypesFields';
import Categories from '../Categories/Categories';
import CategoriesFields from '../Categories/CategoriesFields';
import Types from '../Types/Types';
import TypesFields from '../Types/TypesFields';
import Attachments from '../Attachments/Attachments';
import AttachmentsFields from '../Attachments/AttachmentsFields';
import Rooms from '../Rooms/Rooms';
import RoomsFields from '../Rooms/RoomsFields';
import AdminRoles from '../AdminRoles/AdminRoles';
import AdminRolesFields from '../AdminRoles/AdminRolesFields';
import AdminRoutes from '../AdminRoutes/AdminRoutes';
import AdminRoutesFields from '../AdminRoutes/AdminRoutesFields';
import BeaconIcons from '../BeaconIcons/BeaconIcons';
import BeaconIconsFields from '../BeaconIcons/BeaconIconsFields';
// End Route Content ===========================================================

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenPassed: false,
      menuOpen: true,
    };
  }

  componentDidMount() {
    // Forces users to check their token on any route logging them out if they don't have a valid token
    this.checkToken();
  }

  checkToken = async () => {
    const results = await apiRequest('GET', 'user-check');
    if (results && results.data && !results.data.is_expired) {
      this.setState({ tokenPassed: true });
    }
  };

  toggleMenu = () => {
    const { menuOpen } = this.state;
    this.setState({ menuOpen: !menuOpen });
  };

  getSectionTitle = () => {
    const section = getSectionFromRoute();
    let title = 'ADMIN';
    switch (section) {
      case 'admin-users':
        title = 'ADMIN USERS';
        break;
      case 'admin-user-types':
        title = 'ADMIN USER TYPES';
        break;
      case 'admin-roles':
        title = 'API ROLES';
        break;
      case 'admin-routes':
        title = 'API ROUTES';
        break;
      case 'beacons':
        title = 'BEACONS';
        break;
      case 'beacon-icons':
        title = 'BEACON ICONS';
        break;
      case 'worlds':
        title = 'WORLDS';
        break;
      case 'games':
        title = 'GAMES';
        break;
      case 'attachments':
        title = 'ATTACHMENTS';
        break;
      case 'categories':
        title = 'BEACON CATEGORIES';
        break;
      case 'types':
        title = 'BEACON TYPES';
        break;
      case 'games-types':
        title = 'GAMES TYPES';
        break;
      case 'rooms':
        title = 'SPACES';
        break;
      default:
        break;
    }
    return title;
  };

  render() {
    const { tokenPassed, menuOpen } = this.state;
    if (!tokenPassed) {
      return (
        <Content title={this.getSectionTitle()} bodyMaxWidth={defaultMaxWidth}>
          <Loading />
        </Content>
      );
    } else {
      // https://stackoverflow.com/questions/41914954/how-to-force-a-fixed-column-width-with-a-bootstrap-grid-and-keep-the-other-ones
      return (
        <StateProvider
          subPages={
            <>
              <SideNav menuOpen={menuOpen} toggleMenu={this.toggleMenu} />
              <div className={`main-window${menuOpen ? '' : ' --nav-closed'}`}>
                <Content title={this.getSectionTitle()} bodyMaxWidth={defaultMaxWidth}>
                  <Switch>
                    <Route exact path='/admin/beacons' component={Beacons} />
                    <Route exact path='/admin/beacons/create' component={BeaconsFields} />
                    <Route exact path='/admin/beacons/:id' component={BeaconsFields} />

                    <Route exact path='/admin/admin-users' component={AdminUsers} />
                    <Route exact path='/admin/admin-users/create' component={AdminUsersFields} />
                    <Route exact path='/admin/admin-users/:id' component={AdminUsersFields} />

                    <Route exact path='/admin/admin-user-types' component={AdminUserTypes} />
                    <Route exact path='/admin/admin-user-types/create' component={AdminUserTypesFields} />
                    <Route exact path='/admin/admin-user-types/:id' component={AdminUserTypesFields} />

                    <Route exact path='/admin/worlds' component={Worlds} />
                    <Route exact path='/admin/worlds/create' component={WorldsFields} />
                    <Route exact path='/admin/worlds/:id' component={WorldsFields} />

                    <Route exact path='/admin/games' component={Games} />

                    <Route exact path='/admin/categories' component={Categories} />
                    <Route exact path='/admin/categories/create' component={CategoriesFields} />
                    <Route exact path='/admin/categories/:id' component={CategoriesFields} />

                    <Route exact path='/admin/games' component={Games} />
                    <Route exact path='/admin/games/create' component={GamesFields} />
                    <Route exact path='/admin/games/:id' component={GamesFields} />

                    <Route exact path='/admin/games-types' component={GamesTypes} />
                    <Route exact path='/admin/games-types/create' component={GamesTypesFields} />
                    <Route exact path='/admin/games-types/:id' component={GamesTypesFields} />

                    <Route exact path='/admin/types' component={Types} />
                    <Route exact path='/admin/types/create' component={TypesFields} />
                    <Route exact path='/admin/types/:id' component={TypesFields} />

                    <Route exact path='/admin/attachments' component={Attachments} />
                    <Route exact path='/admin/attachments/create' component={AttachmentsFields} />
                    <Route exact path='/admin/attachments/:id' component={AttachmentsFields} />

                    <Route exact path='/admin/rooms' component={Rooms} />
                    <Route exact path='/admin/rooms/:id' component={RoomsFields} />

                    <Route exact path='/admin/admin-roles' component={AdminRoles} />
                    <Route exact path='/admin/admin-roles/create' component={AdminRolesFields} />
                    <Route exact path='/admin/admin-roles/:id' component={AdminRolesFields} />

                    <Route exact path='/admin/admin-routes' component={AdminRoutes} />
                    <Route exact path='/admin/admin-routes/create' component={AdminRoutesFields} />
                    <Route exact path='/admin/admin-routes/:id' component={AdminRoutesFields} />

                    <Route exact path='/admin/beacon-icons' component={BeaconIcons} />
                    <Route exact path='/admin/beacon-icons/create' component={BeaconIconsFields} />
                    <Route exact path='/admin/beacon-icons/:id' component={BeaconIconsFields} />
                    <Route>
                      <h1>Welcome to the Yondar Admin!</h1>
                      <p>
                        We've provided this admin to streamline the process of creating and managing content within
                        yondar. You can update worlds, beacons, attachments and more using the menu items on the left.
                        This admin will continue to evolve to ensure the experience is as easy as we can make it.
                      </p>
                      <br />
                      <p>To create a world do the following:</p>
                      <ol>
                        <li>
                          Create some <b>BEACONS</b>.
                        </li>
                        <li>
                          Create a <b>WORLD</b>, using the beacons in your list near the end.
                        </li>
                        <li>Enjoy!</li>
                      </ol>
                    </Route>
                  </Switch>
                </Content>
              </div>
            </>
          }
        />
      ); // end return
    }
  }
}

export default Admin;
