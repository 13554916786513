import React, { useEffect, useState, useContext } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactMapGL, { NavigationControl } from 'react-map-gl';
// eslint-disable-next-line import/no-extraneous-dependencies
import mapboxgl from 'mapbox-gl';
import { throttle } from 'lodash';
import { DEFAULT_ZOOM } from '../../constants';
import { getExpandedStyle, iconStyle, beaconPlacement } from './BeaconPickerStyles';
import { StateContext } from '../StateProvider';

const BeaconPicker = props => {
  const mapTheme = [
    'mapbox://styles/innovatar/ckw5d323u03yo15oi8mpbs9os',
    'mapbox://styles/innovatar/ckg6yvzcf0wni19u8ukvr3i2u',
  ];
  const { category, updateLatLongFields, inLatitude, inLongitude, iconId } = props;
  const { beaconIconsMap } = useContext(StateContext); // Essentially replaces Redux - GL
  const [state, setState] = useState({});
  const [selectedMapTheme, setSelectedMapTheme] = useState(0);

  useEffect(() => {
    if (window.location.hostname !== 'localhost') {
      /* 
       We need this, and the above mapbox import to get the new map to work on heroku
       However this also breaks running local host. Without this, localhost is fine, but heroku breaks.
       So we do this conditional on not local host.
      */
      // eslint-disable-next-line import/no-webpack-loader-syntax
      mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line global-require, import/no-unresolved
    }

    const hasProps = !!inLatitude || !!inLongitude;
    const latitude = hasProps ? inLatitude : 46.8341;
    const longitude = hasProps ? inLongitude : -100.7766;

    setState({
      longitude,
      latitude,
      height: '100%',
      width: '100%',
      mapStyle: mapTheme[selectedMapTheme],
      zoom: DEFAULT_ZOOM,
    });
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleWindowResize = throttle(() => {
    setState({
      ...state,
      width: document.querySelector('#beacon-picker').clientWidth,
      height: document.querySelector('#beacon-picker').clientHeight,
    });
  }, 10);

  const setViewport = (nextViewport, interactionState) => {
    setState({
      ...state,
      ...nextViewport,
    });
    if (interactionState?.isDragging || interactionState?.isPanning) {
      updateLatLongFields(nextViewport.latitude.toFixed(14), nextViewport.longitude.toFixed(14));
    }
  };

  const setMapToCoords = () => {
    const nextState = {
      ...state,
      latitude: Number.parseFloat(inLatitude),
      longitude: Number.parseFloat(inLongitude),
    };
    setState(nextState);
  };

  const changeSelectedMap = () => {
    let selectedTheme = 0;
    if (selectedMapTheme === 0) {
      selectedTheme = 1;
    } else {
      selectedTheme = 0;
    }
    setSelectedMapTheme(selectedTheme);

    const nextState = {
      ...state,
      mapStyle: mapTheme[selectedTheme],
    };
    setState(nextState);
  };

  return (
    <div id='beacon-picker' style={{ maxWidth: '100%', height: '500px' }}>
      <ReactMapGL
        {...state}
        onViewportChange={setViewport}
        doubleClickZoom={false}
        scrollZoom={false}
        dragRotate={false}
      >
        <div style={beaconPlacement}>
          <ExpandedBeacon {...getExpandedStyle(category)} />
          <img
            alt='beaconType'
            src={beaconIconsMap[iconId].iconImage}
            style={{
              ...iconStyle,
            }}
          />
        </div>
        <NavigationControl style={{ top: '16px', left: '16px' }} showCompass={false} />

        <button
          type='button'
          className='btn btn-light btn-fancy'
          style={{ position: 'absolute', top: '16px', right: '16px', boxShadow: '2px 2px 2px #00000080' }}
          onClick={() => setMapToCoords()}
        >
          <span>
            Update Map
            <br />
            From Coords
          </span>
        </button>

        <button
          type='button'
          className='btn btn-light btn-fancy'
          style={{ position: 'absolute', top: '96px', right: '16px', boxShadow: '2px 2px 2px #00000080' }}
          onClick={() => changeSelectedMap()}
        >
          <span>
            Switch to
            <br />
            {selectedMapTheme === 0 ? 'Road' : 'Satelite'} Map
          </span>
        </button>
      </ReactMapGL>
    </div>
  );
};

const ExpandedBeacon = props => {
  const { color } = props;
  return (
    <svg xmlns='http://www.w3.org/2000/svg' height='100%' width='100%' viewBox='0 0 116.1 167.96'>
      <g>
        <path
          style={{ fill: color }}
          d='M80,114.1H24.87A22.9,22.9,0,0,1,2,91.23V86.78l1.12-.55a29.13,29.13,0,0,0,7.59-5.33A29.13,29.13,0,0,0,19.6,60.68,29.42,29.42,0,0,0,3.12,33.49L2,32.94V24.87A22.9,22.9,0,0,1,24.87,2H91.23A22.9,22.9,0,0,1,114.1,24.87v7.8l-1.16.54a29.34,29.34,0,0,0,0,53.27l1.16.54v74.72Z'
        />
        <path
          style={{ fill: '#ffffff' }}
          d='M91.23,4A20.87,20.87,0,0,1,112.1,24.87v6.52a31.58,31.58,0,0,0-8.69,6A31.34,31.34,0,0,0,112.1,88.3v67.21L81,112.1H24.87A20.87,20.87,0,0,1,4,91.23V88a31.62,31.62,0,0,0,8.11-5.69A31.19,31.19,0,0,0,21.6,60.73,31.38,31.38,0,0,0,4,31.69V24.87A20.87,20.87,0,0,1,24.87,4H91.23m0-4H24.87A24.9,24.9,0,0,0,0,24.87v9.32l2.24,1.1A27.42,27.42,0,0,1,17.6,60.62,27.2,27.2,0,0,1,9.32,79.47a27.24,27.24,0,0,1-7.07,5L0,85.53v5.7A24.9,24.9,0,0,0,24.87,116.1H78.92l29.93,41.74L116.1,168V85.74l-2.32-1.07a27.34,27.34,0,0,1-7.58-44.43A27.65,27.65,0,0,1,113.78,35L116.1,34V24.87A24.9,24.9,0,0,0,91.23,0Z'
        />
      </g>
    </svg>
  );
};

export default BeaconPicker;
