import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import { getFullTableDataVisibility, getCreateWorldVisibility } from '../../helpers/VisibilityHelpers';
import Loading from '../Loading';

class Worlds extends Component {
  constructor(props) {
    super(props);
    const canSeeFullTableData = getFullTableDataVisibility();
    const canCreateWorld = getCreateWorldVisibility();
    this.state = {
      worlds: [],
      loading: true,
      canSeeFullTableData,
      canCreateWorld,
      worldRoute: canSeeFullTableData ? 'super-worlds' : 'enterprise-worlds',
    };
  }

  componentDidMount() {
    this.getWorlds();
  }

  getWorlds = async () => {
    try {
      const { worldRoute } = this.state;
      const results = await apiRequest('GET', worldRoute);
      let data = [];
      if (results.data) {
        data = results.data;
      }
      this.setState({ worlds: data, loading: false });
    } catch (error) {
      console.error(error);
    }
  };

  listWorldsData = () => {
    const { worlds, canSeeFullTableData } = this.state;
    const output = [];
    for (const world of worlds) {
      output.push(
        <tr key={world.title}>
          {canSeeFullTableData ? <td>{world.id} </td> : null}
          <td>{world.title} </td>
          <td>
            <Link to={`/admin/worlds/${world.uuid}`}>
              <button className='btn btn-secondary' type='button'>
                edit
              </button>
            </Link>
          </td>
        </tr>,
      );
    }
    return output;
  };

  render() {
    const { loading, canSeeFullTableData, canCreateWorld } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          {canCreateWorld ? (
            <div className='my-5'>
              <h2>CREATE WORLD</h2>
              <Link to='/admin/worlds/create'>
                <button className='btn btn-fancy btn-primary py-3 px-4' type='button'>
                  Add World +
                </button>
              </Link>
            </div>
          ) : null}
          <div className='mt-5'>
            <h2>MANAGE WORLDS</h2>
            <table className='table table-striped'>
              <thead>
                <tr>
                  {canSeeFullTableData ? <th>ID</th> : null}
                  <th style={{ width: '100%' }}>World</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>{this.listWorldsData()}</tbody>
            </table>
          </div>
        </>
      );
    }
  }
}

export default Worlds;
