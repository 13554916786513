import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { apiRequest } from '../helpers/AjaxHelpers';
import { PasswordCheck } from '../helpers/PasswordCheck';
import { routeDelay } from '../globals';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { password: '', error: '', success: '', errorState: '--open' };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.debounceReturn = debounce(() => {
      window.location.href = '/';
    }, routeDelay);
    this.debounceClose = debounce(() => this.setState({ errorState: '--closed' }), 2000);
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  resetPassword = async e => {
    try {
      e.preventDefault();
      const { password } = this.state;
      if (PasswordCheck(password) < 2) {
        this.setState({ error: 'Password does not meet requirements.', errorState: '--open' });
        this.debounceClose();
      } else {
        const result = await apiRequest('PUT', 'reset-password', { password, resetting: true });
        if (result.data) {
          this.setState({
            success: 'Password reset. Returning to login.',
            error: '',
            password: '',
            errorState: '--open',
          });
          this.debounceReturn();
        } else if (result.error) {
          this.setState({ error: result.error, password: '', errorState: '--open' });
          this.debounceClose();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { error, errorState, success, password } = this.state;
    return (
      <div className='container-fluid p-0'>
        <div className='bg-header p-0 mb-5'>
          <h1 className='my-0 mx-5 pl-5 text-white text-center'>RESET PASSWORD</h1>
        </div>
        <div className='m-5 d-flex justify-content-center'>
          <form className='card p-4' style={{ width: '400px' }} onSubmit={this.resetPassword}>
            {error ? <p className={`alert alert-danger ${errorState}`}>{error}</p> : null}
            {success ? (
              <>
                <p className='alert alert-success --bar mb-0'>{success}</p>
                <div className='bar-fill mb-3' />
              </>
            ) : null}
            <p>Passwords must have least 8 characters, 1 lower letter, 1 upper letter, and 1 number</p>
            <div className='form-group mb-3  mx-0 row'>
              <input
                className='form-control'
                name=''
                id='inputusername'
                type='password'
                placeholder='New Password'
                value={password}
                onChange={this.handlePasswordChange}
              />
            </div>
            <div className='grid' style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <button className='btn btn-primary btn-fancy py-2 px-4 fw' type='submit'>
                SUBMIT
              </button>
              <Link to='/'>
                <button className='btn btn-secondary btn-fancy py-2 px-4 fw' type='button'>
                  BACK
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    ); // end return
  }
}

export default ResetPassword;
