import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import { getValidationErrorMessage, getErrorFields } from '../../helpers/ErrorHelpers';
import Loading from '../Loading';
import { shortRouteDelay } from '../../globals';

class AdminUserTypesFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isEditing: false,
      errorMessage: '',
      successMessage: '',
      messageState: '--open',
      errorFields: [],
    };
    this.adminUserTypesRoute = 'admin-user-types';
    this.parentPage = 'admin-user-types';
    this.nameRef = React.createRef();
    this.descriptionRef = React.createRef();
    this.debounceReturn = debounce(() => {
      window.location.href = `/admin/${this.parentPage}`;
    }, shortRouteDelay);
  }

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (!id) {
      this.setState({ loading: false, isEditing: false });
    } else {
      this.getAdminUserType(id);
    }
  }

  getAdminUserType = async id => {
    try {
      const results = await apiRequest('GET', `${this.adminUserTypesRoute}/${id}`);
      this.setState({ loading: false, isEditing: true });
      if (results.data) {
        this.nameRef.current.value = results.data.name;
        this.descriptionRef.current.value = results.data.description;
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  submitForm = async event => {
    const { isEditing } = this.state;
    const { match } = this.props;
    try {
      event.preventDefault();
      const requestBody = {
        name: this.nameRef.current.value,
        description: this.descriptionRef.current.value,
      };

      let data = null;
      if (isEditing) {
        data = await apiRequest('PUT', `${this.adminUserTypesRoute}/${match.params.id}`, requestBody);
      } else {
        data = await apiRequest('POST', `${this.adminUserTypesRoute}/`, requestBody);
      }

      // scroll after request
      window.scrollTo(0, 0);

      if (!data) {
        throw new Error('request failed');
      } else if (data.error) {
        this.setState({
          errorMessage: getValidationErrorMessage(data.error),
          errorFields: getErrorFields(data.error),
          messageState: '--open',
        });
      } else {
        this.setState({ errorMessage: '', successMessage: 'Type Updated!' });
        this.debounceReturn();
      }
    } catch (error) {
      this.setState({ errorMessage: 'Something went wrong. Please refresh and try again.', messageState: '--open' });
      console.error(error);
    }
  };

  render() {
    const { loading, isEditing, errorMessage, messageState, successMessage, errorFields } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          <div className='my-5'>
            <h2>{isEditing ? 'EDIT' : 'CREATE'} TYPE</h2>
          </div>
          <form className='card p-4 fw' onSubmit={this.submitForm}>
            {errorMessage ? <p className={`alert alert-danger ${messageState}`}>{errorMessage}</p> : null}
            {successMessage ? (
              <>
                <p className='mb-0 alert alert-success --bar '>{successMessage}</p>
                <div className='bar-fill mb-3 --quick' />
              </>
            ) : null}
            <div className='grid mb-4' style={{ gridTemplateColumns: 'auto 1fr' }}>
              <label className={`col-form-label${errorFields.includes('name') ? ' text-danger' : ''}`} htmlFor='name'>
                Name *
              </label>
              <input
                className={`form-control${errorFields.includes('name') ? ' is-invalid' : ''}`}
                id='name'
                type='text'
                ref={this.nameRef}
                disabled={isEditing}
              />
              <label
                className={`col-form-label${errorFields.includes('description') ? ' text-danger' : ''}`}
                htmlFor='description'
              >
                Description
              </label>
              <textarea
                className={`form-control${errorFields.includes('description') ? ' is-invalid' : ''}`}
                id='description'
                type='text'
                ref={this.descriptionRef}
              />
            </div>
            <div className='grid' style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <button className='btn btn-primary btn-fancy py-2 px-4 fw' type='submit'>
                SAVE
              </button>
              <Link to={`/admin/${this.parentPage}`}>
                <button className='btn btn-danger btn-fancy py-2 px-4 fw' type='button'>
                  BACK
                </button>
              </Link>
            </div>
          </form>
        </>
      ); // end return
    }
  }
}

export default AdminUserTypesFields;
