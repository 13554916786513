/* eslint-disable camelcase */
import React, { useEffect, useState, useContext } from 'react';
import debounce from 'lodash/debounce';
import Select from 'react-select';
import Loading from '../Loading';
import BeaconPicker from '../BeaconPicker/BeaconPicker';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import { getValidationErrorMessage, getErrorFields } from '../../helpers/ErrorHelpers';
import { getFullTableDataVisibility, getCreateAttachmentVisibility } from '../../helpers/VisibilityHelpers';
import { shortRouteDelay, errorColor } from '../../globals';
import { getPrettyDate } from '../../helpers/DateHelpers';
import { StateContext } from '../StateProvider';
import { BEACON_COLORS } from '../../constants';

const BeaconsFields = props => {
  const canSeeFullTableData = getFullTableDataVisibility();
  const canCreateAttachment = getCreateAttachmentVisibility();
  const beaconRoute = canSeeFullTableData ? 'super-beacons' : 'enterprise-beacons';
  const worldRoute = canSeeFullTableData ? 'super-worlds' : 'enterprise-worlds';
  const attachmentRoute = canSeeFullTableData ? 'super-attachments' : 'enterprise-attachments';
  const { beaconIconsMap } = useContext(StateContext); // Essentially replaces Redux - GL

  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [beacon, setBeacon] = useState(null);
  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [worlds, setWorlds] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorFields, setErrorFields] = useState([]);
  const [attachments, setAttachments] = useState(null);
  const [iconOptions, setIconOptions] = useState([]);
  const debounceReturn = debounce(() => {
    window.location.href = '/admin/beacons';
  }, shortRouteDelay);

  const [beaconTitle, setBeaconTitle] = useState('');
  const [beaconDescription, setBeaconDescription] = useState('');
  const [beaconTags, setBeaconTags] = useState('');
  const [beaconActive, setBeaconActive] = useState('');
  const [beaconExpire, setBeaconExpire] = useState('');
  const [beaconVisibleDistance, setBeaconVisibleDistance] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [userSelected, setUserSelected] = useState(null);
  const [typeSelected, setTypeSelected] = useState('Generic');
  const [categorySelected, setCategorySelected] = useState('default');
  const [iconSelected, setIconSelected] = useState(6);

  const style = {
    backgroundColor: BEACON_COLORS.default,
    borderRadius: '4px',
    padding: '4px',
    height: '40px',
    width: '40px',
    marginRight: '8px',
    border: 'none',
  };
  const selectedStyle = {
    backgroundColor: categorySelected?.value ? BEACON_COLORS[categorySelected?.value] : 'blue',
    borderRadius: '4px',
    padding: '4px',
    height: '46px',
    width: '46px',
    marginRight: '8px',
  };

  // Runs first time and once only
  useEffect(() => {
    setupPageData();
  }, []);

  // Runs first time and when beacon changes
  useEffect(() => {
    populateBeaconFields();
  }, [beacon]);

  useEffect(() => {
    setIconOptionsForType();
  }, [typeSelected, iconSelected, categorySelected]);

  const setupPageData = async () => {
    try {
      const { match } = props;
      const { id } = match.params;
      const promises = [setStateFromApi(setTypes, 'types'), setStateFromApi(setCategories, 'categories')];
      if (canSeeFullTableData) {
        promises.push(setStateFromApi(setUsers, 'admin-users/active', false));
      }
      await Promise.all(promises);
      if (id) {
        await Promise.all([
          setStateFromApi(setBeacon, `${beaconRoute}/${id}`, false),
          setStateFromApi(setWorlds, `${worldRoute}/titles-by-beacon/${id}`),
          setStateFromApi(setAttachments, `${attachmentRoute}/beacon/${id}`),
        ]);
        setIsEditing(true);
      }
      setLoading(false);
    } catch (error) {
      displayErrorMessage(error, 'Something went wrong. Please refresh and try again.');
    }
  };

  const displayErrorMessage = (error, message) => {
    setErrorMessage(message);
    setSuccessMessage('');
    console.error(error);
    window.scrollTo(0, 0);
  };

  const setStateFromApi = async (setFunction, route, checkForRows = true) => {
    try {
      const results = await apiRequest('GET', route);
      if (results.data && (checkForRows ? results.data.length > 0 : true)) {
        setFunction(results.data);
      }
    } catch (error) {
      displayErrorMessage(error, 'Something went wrong. Please refresh and try again.');
    }
  };

  const getTypeOptions = () => {
    const typeOptions = [];
    for (const type of types) {
      typeOptions.push({ value: type.id, label: type.name });
    }
    return typeOptions;
  };

  const getCategoryOptions = () => {
    const categoryOptions = [];
    for (const category of categories) {
      if (category.name !== 'default') {
        categoryOptions.push({ value: category.name, label: category.name });
      }
    }
    return categoryOptions;
  };

  const getUserOptions = () => {
    const userOptions = [];
    for (const user of users) {
      userOptions.push({ value: user?.id, label: user?.username });
    }
    return userOptions;
  };

  const populateBeaconFields = () => {
    if (beacon !== null) {
      const filteredType = types.find(type => {
        return type.name === beacon.type;
      });
      const filteredCategory = categories.find(category => {
        return category.name === beacon.category;
      });
      const filteredUser = users.find(user => {
        return user?.id === beacon.user_id;
      });
      const typeSelected = { value: filteredType?.id, label: filteredType?.name };
      const categorySelected = { value: filteredCategory?.name, label: filteredCategory?.name };
      const userSelected = { value: filteredUser?.id, label: filteredUser?.username };

      setLatitude(beacon?.position.y ?? '');
      setLongitude(beacon?.position.x ?? '');
      setBeaconTitle(beacon.title ?? '');
      setBeaconDescription(beacon.description ?? '');
      setBeaconTags(beacon.tags ?? '');
      setBeaconActive(getPrettyDate(beacon.active) ?? '');
      setBeaconExpire(getPrettyDate(beacon.expiration) ?? '');
      setBeaconVisibleDistance(beacon.visible_distance ?? '');
      setTypeSelected(typeSelected ?? '');
      setCategorySelected(categorySelected ?? '');
      setUserSelected(userSelected ?? '');
      setIconSelected(beacon.icon_id ?? 6);
    } else {
      const categorySelected = { value: 'activities', label: 'activities' };
      setTypeSelected(typeSelected);
      setCategorySelected(categorySelected);
    }
  };

  const submitForm = async event => {
    const { match } = props;
    try {
      event.preventDefault();
      const activeDate = beaconActive ? new Date(beaconActive).toISOString() : '';
      const expiresDate = beaconExpire ? new Date(beaconExpire).toISOString() : '';
      const requestBody = {
        long: longitude,
        lat: latitude,
        title: beaconTitle,
        description: beaconDescription,
        tags: beaconTags,
        active: activeDate,
        expire: expiresDate,
        type: typeSelected?.label,
        category: categorySelected?.value,
        userId: userSelected?.value,
        visibleDistance: beaconVisibleDistance,
        typeId: typeSelected?.value ?? 1,
        iconId: iconSelected ?? 6,
      };

      let data = null;
      if (isEditing) {
        data = await apiRequest('PUT', `${beaconRoute}/${match.params.id}/`, requestBody);
      } else {
        data = await apiRequest('POST', `${beaconRoute}/`, requestBody);
      }

      // scroll after request
      window.scrollTo(0, 0);

      if (!data) {
        throw new Error('request failed');
      } else if (data.error) {
        displayErrorMessage(data.error, getValidationErrorMessage(data.error));
        setErrorFields(getErrorFields(data.error));
      } else {
        setErrorMessage('');
        setSuccessMessage('Beacon Updated');
        debounceReturn();
      }
    } catch (error) {
      displayErrorMessage(error, 'Something went wrong. Please refresh and try again.');
    }
  };

  const updateLatLongFields = (newLat, newLong) => {
    setLatitude(newLat);
    setLongitude(newLong);
  };

  const getWorldsList = () => {
    const output = [];
    for (const world of worlds) {
      output.push(
        <Link style={{ marginRight: '8px' }} key={world.uuid} to={`/admin/worlds/${world.uuid}`} target='_blank'>
          {world.title}
        </Link>,
      );
    }
    return output;
  };

  const getAttachmentsList = () => {
    const output = [];
    if (canCreateAttachment && isEditing) {
      output.push(
        <Link
          style={{ marginRight: '8px' }}
          to={`/admin/attachments/create?beacon=${beacon.id}`}
          key='0'
          target='_blank'
        >
          + Create Attachment
        </Link>,
      );
    }
    if (attachments) {
      for (const attachment of attachments) {
        output.push(
          <Link
            style={{ marginRight: '8px' }}
            key={attachment.id}
            to={`/admin/attachments/${attachment.id}`}
            target='_blank'
          >
            {attachment.attachment_label ? attachment.attachment_label : 'unlabeled attachment'}
          </Link>,
        );
      }
    }
    return output;
  };

  const setIconOptionsForType = () => {
    const selectedType = types.find(type => type.id === typeSelected.value);
    const output = [];
    let selectedIcon = iconSelected;
    if (selectedType?.allowed_icon_ids) {
      if (!selectedType.allowed_icon_ids.includes(iconSelected)) {
        setIconSelected(selectedType.allowed_icon_ids[0]);
        // eslint-disable-next-line prefer-destructuring
        selectedIcon = selectedType.allowed_icon_ids[0];
      }
      for (const id of selectedType.allowed_icon_ids) {
        output.push(
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
          <img
            style={id === selectedIcon ? selectedStyle : style}
            src={beaconIconsMap[id].iconImage}
            alt={beaconIconsMap[id].file_name}
            key={id}
            onClick={() => setIconSelected(id)}
          />,
        );
      }
    }
    setIconOptions(output);
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <div className='my-5'>
          <h2>{isEditing ? 'EDIT' : 'CREATE'} BEACON</h2>
        </div>
        <form className='card p-4 fw' onSubmit={submitForm}>
          {errorMessage ? <p className='alert alert-danger --open'>{errorMessage}</p> : null}
          {successMessage ? (
            <>
              <p className='mb-0 alert alert-success --bar '>{successMessage}</p>
              <div className='bar-fill mb-3 --quick' />
            </>
          ) : null}
          <div className='grid mb-4' style={{ gridTemplateColumns: 'auto 1fr' }}>
            {isEditing && beacon && canSeeFullTableData ? (
              <>
                <span className='col-form-label'>ID </span>
                <span>{beacon.id}</span>
              </>
            ) : null}
            {isEditing && beacon && canSeeFullTableData ? (
              <>
                <span className='col-form-label'>UUID </span>
                <span>{beacon.uuid}</span>
              </>
            ) : null}
            {worlds ? (
              <>
                <label className='col-form-label'>Part of worlds</label>
                <span>{getWorldsList()}</span>
              </>
            ) : null}
            {attachments || (canCreateAttachment && isEditing) ? (
              <>
                <label className='col-form-label'>Attachments</label>
                <span>{getAttachmentsList()}</span>
              </>
            ) : null}
            <label className={`col-form-label${errorFields.includes('lattitude') ? ' text-danger' : ''}`} htmlFor='lat'>
              Latitude *
            </label>
            <input
              className={`form-control${errorFields.includes('lattitude') ? ' is-invalid' : ''}`}
              id='lat'
              type='number'
              step={0.00000000000001}
              onChange={e => setLatitude(e.target.value)}
              value={latitude}
            />

            <label
              className={`col-form-label${errorFields.includes('longitude') ? ' text-danger' : ''}`}
              htmlFor='long'
            >
              Longitude *
            </label>
            <input
              className={`form-control${errorFields.includes('longitude') ? ' is-invalid' : ''}`}
              id='long'
              type='number'
              step={0.00000000000001}
              onChange={e => setLongitude(e.target.value)}
              value={longitude}
            />

            <label className={`col-form-label${errorFields.includes('title') ? ' text-danger' : ''}`} htmlFor='title'>
              Title *
            </label>
            <input
              className={`form-control${errorFields.includes('title') ? ' is-invalid' : ''}`}
              id='title'
              type='text'
              onChange={e => setBeaconTitle(e.target.value)}
              value={beaconTitle}
            />

            <label className='col-form-label' htmlFor='description'>
              Description
              <br />
              <a
                href='https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet'
                target='_blank'
                rel='noopener noreferrer'
              >
                Supports Markdown
              </a>
            </label>
            <textarea
              className='form-control monospace'
              id='description'
              rows='12'
              onChange={e => setBeaconDescription(e.target.value)}
              value={beaconDescription}
            />

            <label className='col-form-label' htmlFor='tags'>
              Tags
            </label>
            <input
              className='form-control'
              id='tags'
              type='text'
              onChange={e => setBeaconTags(e.target.value)}
              value={beaconTags}
            />

            <label
              className={`col-form-label${errorFields.includes('userId') ? ' text-danger' : ''}`}
              htmlFor='userId'
              hidden={!canSeeFullTableData}
            >
              Owner ID *
            </label>
            {canSeeFullTableData ? (
              <Select
                id='userId'
                styles={{
                  control: base =>
                    errorFields.includes('userId') ? { ...base, borderColor: errorColor } : { ...base },
                }}
                value={userSelected}
                onChange={e => setUserSelected(e)}
                options={getUserOptions()}
                isSearchable
              />
            ) : null}

            <label
              className={`col-form-label${errorFields.includes('active') ? ' text-danger' : ''}`}
              htmlFor='active'
              hidden={!canSeeFullTableData}
            >
              Active *
            </label>
            <input
              className={`form-control${errorFields.includes('active') ? ' is-invalid' : ''}`}
              id='active'
              type='text'
              hidden={!canSeeFullTableData}
              onChange={e => setBeaconActive(e.target.value)}
              value={beaconActive}
            />

            <label className='col-form-label' htmlFor='expire' hidden={!canSeeFullTableData}>
              Expiration
            </label>
            <input
              className='form-control'
              id='expire'
              type='text'
              onChange={e => setBeaconExpire(e.target.value)}
              value={beaconExpire}
              hidden={!canSeeFullTableData}
            />

            <label
              className={`col-form-label${errorFields.includes('visibleDistance') ? ' text-danger' : ''}`}
              htmlFor='visibleDistance'
            >
              Visible Distance (in feet)
            </label>
            <input
              className={`form-control${errorFields.includes('visibleDistance') ? ' is-invalid' : ''}`}
              id='visibleDistance'
              type='number'
              onChange={e => setBeaconVisibleDistance(e.target.value)}
              value={beaconVisibleDistance}
            />

            <label className={`col-form-label${errorFields.includes('typeId') ? ' text-danger' : ''}`} htmlFor='type'>
              Type *
            </label>
            <Select
              id='type'
              styles={{
                control: base => (errorFields.includes('type') ? { ...base, borderColor: errorColor } : { ...base }),
              }}
              value={typeSelected}
              onChange={e => setTypeSelected(e)}
              options={getTypeOptions()}
              isSearchable
            />

            <label className={`col-form-label${errorFields.includes('category') ? ' text-danger' : ''}`} htmlFor='type'>
              Category *
            </label>
            <Select
              id='category'
              styles={{
                control: base =>
                  errorFields.includes('category') ? { ...base, borderColor: errorColor } : { ...base },
              }}
              value={categorySelected}
              onChange={e => setCategorySelected(e)}
              options={getCategoryOptions()}
              isSearchable
            />

            <label className={`col-form-label${errorFields.includes('iconId') ? ' text-danger' : ''}`} htmlFor='type'>
              Icon *
            </label>
            <span>{iconOptions}</span>
          </div>
          <div className='grid' style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
            <button className='btn btn-primary btn-fancy fw py-2 px-4' type='submit'>
              SAVE
            </button>
            <Link to='/admin/beacons'>
              <button className='btn btn-danger btn-fancy fw py-2 px-4' type='button'>
                BACK
              </button>
            </Link>
          </div>
          <hr />
          <BeaconPicker
            updateLatLongFields={updateLatLongFields}
            inLatitude={latitude}
            inLongitude={longitude}
            category={categorySelected.value}
            type={typeSelected.value}
            iconId={iconSelected}
          />
        </form>
      </>
    ); // end return
  }
};

export default BeaconsFields;
