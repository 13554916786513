import React from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import { getAdminSideNavItemsVisibility } from '../../helpers/VisibilityHelpers';
import { NAV_ICONS } from '../../constants';
import { getSectionFromRoute } from '../../helpers/RouteHelpers';
import yondarLogo from '../../img/YondarLogo.png';
import navButton from '../../img/icons/icons-light-back.svg';

const logout = () => {
  // we don't need to wait we'll trust the api will do what it needs as this is a simple request.
  apiRequest('PUT', 'logout');
  window.localStorage.setItem('token', null);
  window.location.href = '/';
};

const SideNav = props => {
  const { toggleMenu, menuOpen } = props;
  const canSeeAdminSideNavItems = getAdminSideNavItemsVisibility();
  return (
    <div className={`left-nav${menuOpen ? '' : ' --nav-closed'}`}>
      <div className='nav-section'>
        <div className='spacer'>
          <button type='button' className='btn rounded-0 text-light p-0' onClick={toggleMenu}>
            <img src={navButton} alt='menu button' />
          </button>
        </div>
        <Link to='/admin'>
          <img src={yondarLogo} alt='yondar beta logo' />
        </Link>
      </div>
      {canSeeAdminSideNavItems ? (
        <div className='nav-section'>
          <div className='spacer' />
          <div className='menu-container'>
            <Link to='/admin/admin-users' className={getSectionFromRoute() === 'admin-users' ? 'active' : ''}>
              <div className='navIcon'>
                <img className='invert' src={NAV_ICONS.users} alt='beacon icon' />
              </div>
              <span>ADMIN USERS</span>
            </Link>
            <Link to='/admin/admin-user-types' className={getSectionFromRoute() === 'admin-user-types' ? 'active' : ''}>
              <div className='navIcon'>
                <img className='invert' src={NAV_ICONS.users} alt='beacon icon' />
              </div>
              <span>USER TYPES</span>
            </Link>
            <Link to='/admin/admin-roles' className={getSectionFromRoute() === 'admin-roles' ? 'active' : ''}>
              <div className='navIcon'>
                <img className='invert' src={NAV_ICONS.users} alt='beacon icon' />
              </div>
              <span>API ROLES</span>
            </Link>
            <Link to='/admin/admin-routes' className={getSectionFromRoute() === 'admin-routes' ? 'active' : ''}>
              <div className='navIcon'>
                <img className='invert' src={NAV_ICONS.beaconCategories} alt='beacon icon' />
              </div>
              <span>API ROUTES</span>
            </Link>
          </div>
        </div>
      ) : null}
      <div className='nav-section'>
        <div className='spacer' />
        <div className='menu-container'>
          <Link to='/admin/beacons' className={getSectionFromRoute() === 'beacons' ? 'active' : ''}>
            <div className='navIcon'>
              <img src={NAV_ICONS.beacons} alt='beacon icon' />
            </div>
            <span>BEACONS</span>
          </Link>
          <Link to='/admin/worlds' className={getSectionFromRoute() === 'worlds' ? 'active' : ''}>
            <div className='navIcon'>
              <img src={NAV_ICONS.worlds} alt='world icon' />
            </div>
            <span>WORLDS</span>
          </Link>
          <Link to='/admin/games' className={getSectionFromRoute() === 'games' ? 'active' : ''}>
            <div className='navIcon'>
              <img src={NAV_ICONS.games} alt='games icon' />
            </div>
            <span>GAMES</span>
          </Link>
          <Link to='/admin/attachments' className={getSectionFromRoute() === 'attachments' ? 'active' : ''}>
            <div className='navIcon'>
              <img className='invert' src={NAV_ICONS.attachments} alt='beacon icon' />
            </div>
            <span>ATTACHMENTS</span>
          </Link>
        </div>
      </div>
      {canSeeAdminSideNavItems ? (
        <div className='nav-section'>
          <div className='spacer' />
          <div className='menu-container'>
            <Link to='/admin/categories' className={getSectionFromRoute() === 'categories' ? 'active' : ''}>
              <div className='navIcon'>
                <img className='invert' src={NAV_ICONS.beaconCategories} alt='beacon icon' />
              </div>
              <span>BCN. CATEGORIES</span>
            </Link>
            <Link to='/admin/types' className={getSectionFromRoute() === 'types' ? 'active' : ''}>
              <div className='navIcon'>
                <img className='invert' src={NAV_ICONS.beaconTypes} alt='beacon icon' />
              </div>
              <span>BEACON TYPES</span>
            </Link>
            <Link to='/admin/beacon-icons' className={getSectionFromRoute() === 'beacon-icons' ? 'active' : ''}>
              <div className='navIcon'>
                <img className='invert' src={NAV_ICONS.beaconTypes} alt='beacon icon' />
              </div>
              <span>BEACON ICONS</span>
            </Link>
            <Link to='/admin/games-types' className={getSectionFromRoute() === 'games-types' ? 'active' : ''}>
              <div className='navIcon'>
                <img className='invert' src={NAV_ICONS.gamesTypes} alt='beacon icon' />
              </div>
              <span>GAMES TYPES</span>
            </Link>
            <Link to='/admin/rooms' className={getSectionFromRoute() === 'rooms' ? 'active' : ''}>
              <div className='navIcon'>
                <img className='invert' src={NAV_ICONS.spaces} alt='beacon icon' />
              </div>
              <span>SPACES</span>
            </Link>
          </div>
        </div>
      ) : null}
      <div className='nav-section'>
        <div className='spacer' />
        <div className='menu-container'>
          <button className='btn btn-danger rounded-0 d-flex fw' type='button' onClick={() => logout()}>
            <div className='navIcon'>
              <img className='invert' src={NAV_ICONS.logout} alt='beacon icon' />
            </div>
            <span className='my-auto'>LOG OUT</span>
          </button>
        </div>
      </div>
      <div className='fill-bottom'>
        <div className='nav-section'>
          <div className='spacer' />
          <span>&nbsp;</span>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
