import React, { useState, useContext, useEffect } from 'react';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import { BEACON_COLORS } from '../../constants';
import { StateContext } from '../StateProvider';

const BeaconIcons = () => {
  const { beaconIcons } = useContext(StateContext); // Essentially replaces Redux - GL
  const [loading, setLoading] = useState(true);

  const style = {
    backgroundColor: BEACON_COLORS.default,
    borderRadius: '4px',
    padding: '4px',
    height: '40px',
    width: '40px',
  };

  // essentially replaces componentDidUpdate - GL
  useEffect(() => {
    if (beaconIcons.length > 0) {
      setLoading(false);
    }
  }, [beaconIcons]); // This means we're going to fire useEffect only when beaconIcons (from global state) changes - GL

  const listBeaconIconData = () => {
    const output = [];
    if (beaconIcons.length > 0) {
      for (const icon of beaconIcons) {
        output.push(
          <tr key={icon.id}>
            <td>
              <img style={style} src={icon.iconImage} alt={`${icon.file_name} icon`} />
            </td>
            <td className='align-middle'>{icon.file_name}</td>
            <td>
              <Link to={`/admin/beacon-icons/${icon.id}`}>
                <button className='btn btn-secondary' type='button'>
                  edit
                </button>
              </Link>
            </td>
          </tr>,
        );
      }
    }
    return output;
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className='mt-5'>
        <h2>MANAGE BEACON ICONS</h2>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Type</th>
              <th style={{ width: '100%' }}>&nbsp;</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>{listBeaconIconData()}</tbody>
        </table>
      </div>
    );
  }
};

export default BeaconIcons;
