import React from 'react';

const Loading = () => {
  return (
    <div className='my-5'>
      <h2>Please Wait...</h2>
    </div>
  );
};

export default Loading;
