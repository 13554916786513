import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import Loading from '../Loading';

class adminRoles extends Component {
  constructor(props) {
    super(props);
    this.state = { adminRoles: [], loading: true };
  }

  componentDidMount() {
    this.getAdminRoles();
  }

  getAdminRoles = async () => {
    const results = await apiRequest('GET', 'admin-roles');
    if (results.data) {
      this.setState({ adminRoles: results.data });
    }
    this.setState({
      loading: false,
    });
  };

  getAdminRolesData = () => {
    const { adminRoles } = this.state;
    const output = [];
    if (adminRoles !== null) {
      for (const adminRole of adminRoles) {
        output.push(
          <tr key={adminRole.id}>
            <td>{adminRole.display_name}</td>
            <td>
              <Link to={`/admin/admin-roles/${adminRole.id}`}>
                <button className='btn btn-secondary' type='button'>
                  edit
                </button>
              </Link>
            </td>
          </tr>,
        );
      }
    }
    return output;
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          <div className='mt-5'>
            <h2>MANAGE ROLES</h2>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th style={{ width: '100%' }}>Role</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>{this.getAdminRolesData()}</tbody>
            </table>
          </div>
        </>
      );
    }
  }
}

export default adminRoles;
