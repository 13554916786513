/*
  LEAVE THIS PAGE MOSTLY EMPTY! If we want a login here, create a component and inject it here, but PLEASE keep this project clean! - Grayson
  the Yondar Project App.js is a great example. This page should handle routing, and little else.
*/
import React from 'react';
import './scss/App.scss';
import { Switch, Route, Redirect } from 'react-router-dom';
import { API_URL } from './config';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import PageNotFound from './components/PageNotFound';
import ServerError from './components/ServerError';
import Admin from './components/Admin/Admin';
import ResetPassword from './components/ResetPassword';

if (!API_URL) {
  throw new Error('No API URL configured.');
}

export const App = () => {
  return (
    <div className='App'>
      <Switch>
        {/* PUBLIC ROUTES - These should be accessable by everyone */}
        <Route exact path='/404' component={PageNotFound} />
        <Route exact path='/500' component={ServerError} />
        <Route exact path='/' component={Login} />
        <Route exact path='/forgot-password' component={ForgotPassword} />
        <Route exact path='/reset-password' component={ResetPassword} />

        {/* PRIVATE ROUTES - These should all require validating against the server before doing anything. 
            If the token passed is not validated, kick them back to login screen as hard as possible.
            If the token passed is validated, the pages can function as normal.
          */}

        <Route path='/admin' component={Admin} />

        {/* DEFAULT ROUTE - If any of the above routes are not found, redirect to 404 */}
        <Redirect to='/404' />
      </Switch>
    </div>
  );
};

export default App;
