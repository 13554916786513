import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import Loading from '../Loading';

class Admins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admins: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getAdmins();
  }

  getAdmins = async () => {
    const results = await apiRequest('GET', 'admin-users');
    let data = [];
    if (results.data) {
      data = results.data;
      for (const user of data) {
        user.expanded = false;
      }
    }
    this.setState({
      admins: data,
      loading: false,
    });
  };

  deactivateUser = async userId => {
    const { admins } = this.state;
    const results = await apiRequest('PUT', `admin-users/${userId}/deactivate`);
    if (results.data) {
      const user = admins.find(user => user.admin_uuid === userId);
      user.is_active = false;
      this.setState({
        admins: [...admins],
      });
    }
  };

  reactivateUser = async userId => {
    const { admins } = this.state;
    const results = await apiRequest('PUT', `admin-users/${userId}/reactivate`);
    if (results.data) {
      const user = admins.find(user => user.admin_uuid === userId);
      user.is_active = true;
      this.setState({
        admins: [...admins],
      });
    }
  };

  listUserData = () => {
    const { admins } = this.state;
    const output = [];
    for (const user of admins) {
      output.push(
        <tr key={user.admin_uuid}>
          <td>
            {' '}
            <span>{user.username} </span>{' '}
          </td>
          <td>
            <Link to={`/admin/admin-users/${user.admin_uuid}`}>
              <button className='btn btn-secondary' type='button'>
                edit
              </button>
            </Link>
          </td>
          <td>
            {' '}
            {user.is_active ? (
              <button className='btn btn-danger' onClick={() => this.deactivateUser(user.admin_uuid)} type='button'>
                deactivate
              </button>
            ) : (
              <button className='btn btn-warning' onClick={() => this.reactivateUser(user.admin_uuid)} type='button'>
                reactivate
              </button>
            )}{' '}
          </td>
        </tr>,
      );
    }
    return output;
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          <div className='my-5'>
            <h2>CREATE USER</h2>
            <Link to='/admin/admin-users/create'>
              <button className='btn btn-fancy btn-primary py-3 px-4' type='button'>
                Add User +
              </button>
            </Link>
          </div>

          <div className='mt-5'>
            <h2>MANAGE USERS</h2>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th style={{ width: '100%' }}>Username</th>
                  <th> </th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>{this.listUserData()}</tbody>
            </table>
          </div>
        </>
      );
    }
  }
}

export default Admins;
