import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../helpers/AjaxHelpers';
import { getFullTableDataVisibility, getCreateBeaconVisibility } from '../../helpers/VisibilityHelpers';
import { BEACON_COLORS } from '../../constants';
import Loading from '../Loading';
import { StateContext } from '../StateProvider';

const getBeaconStyle = category => {
  const style = {
    backgroundColor: BEACON_COLORS[category],
    borderRadius: '4px',
    padding: '4px',
    height: '40px',
    width: '40px',
  };
  return style;
};

const Beacons = () => {
  const [loading, setLoading] = useState(true);
  const [beacons, setBeacons] = useState([]);
  const canSeeFullTableData = getFullTableDataVisibility();
  const canCreateBeacons = getCreateBeaconVisibility();
  const beaconRoute = canSeeFullTableData ? 'super-beacons' : 'enterprise-beacons';
  const [searchTerm, setSearchTerm] = useState('');
  const [beaconWorlds, setBeaconWorlds] = useState({});
  const worldRoute = canSeeFullTableData ? 'super-worlds' : 'enterprise-worlds';

  const getWorlds = useCallback(
    async beaconUuid => {
      try {
        const worldResults = await apiRequest('GET', `${worldRoute}/titles-by-beacon/${beaconUuid}`);
        setBeaconWorlds(prev => ({ ...prev, [beaconUuid]: worldResults.data || [] }));
      } catch (error) {
        console.error(error);
      }
    },
    [worldRoute],
  );

  useEffect(() => {
    getBeacons();
  }, []);

  const getBeacons = async () => {
    try {
      const results = await apiRequest('GET', beaconRoute);
      if (results.data) {
        const beaconList = results.data;
        for (const beacon of beaconList) {
          beacon.title = beacon.is_expired ? `${beacon.title} (expired)` : beacon.title;
        }
        setBeacons(beaconList);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const listBeaconData = () => {
    const output = [];
    const filteredBeacons = beacons.filter(
      beacon =>
        beacon.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (beaconWorlds[beacon.uuid] || []).some(world => world.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
        beacon.title.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    for (const beacon of filteredBeacons) {
      output.push(
        <BeaconRow
          key={beacon.id}
          beacon={beacon}
          canSeeFullTableData={canSeeFullTableData}
          getWorlds={getWorlds}
          worlds={beaconWorlds[beacon.uuid] || []}
        />,
      );
    }

    return output;
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        {canCreateBeacons ? (
          <div className='my-5'>
            <h2>CREATE BEACON</h2>
            <Link to='/admin/beacons/create'>
              <button className='btn btn-fancy btn-primary py-3 px-4' type='button'>
                Add Beacon+
              </button>
            </Link>
          </div>
        ) : null}

        <div className='mt-5'>
          <h2>MANAGE BEACONS</h2>
          <div className='my-3'>
            <label htmlFor='search'>Search by username, world, or beacon title</label>
            <br />
            <input
              id='search'
              type='text'
              placeholder='Search'
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Icon</th>
                <th style={{ width: '70%' }}>Title</th>
                <th>Category</th>
                <th>Type</th>
                {canSeeFullTableData ? <th>ID</th> : null}
                {canSeeFullTableData ? <th>User</th> : null}
                <th style={{ width: '30%' }}>Worlds</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{listBeaconData()}</tbody>
          </table>
        </div>
      </>
    );
  }
};

export default Beacons;

const BeaconRow = ({ beacon, canSeeFullTableData, getWorlds, worlds }) => {
  const { beaconIconsMap } = useContext(StateContext); // Essentially replaces Redux - GL

  useEffect(() => {
    getWorlds(beacon.uuid);
  }, [beacon.uuid, getWorlds]);

  return (
    <tr key={beacon.title}>
      <td>
        <img
          style={getBeaconStyle(beacon.category)}
          src={beaconIconsMap[beacon.icon_id]?.iconImage}
          alt={beacon.type}
        />
      </td>
      <td className='align-middle'>
        <span style={{ color: BEACON_COLORS[beacon.category] }}>{beacon.title}</span>
      </td>
      <td className='align-middle'>
        <span style={{ color: BEACON_COLORS[beacon.category] }}>{beacon.category}</span>
      </td>
      <td className='align-middle'>
        <span style={{ color: BEACON_COLORS[beacon.category] }}>{beacon.type}</span>
      </td>
      {canSeeFullTableData ? <td>{beacon.id}</td> : null}
      {canSeeFullTableData ? <td>{beacon.username}</td> : null}
      <td className='align-middle'>
        {worlds.map((world, index, arr) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index} style={{ color: `#${world.uuid.slice(0, 6)}` }}>
            {world.title}
            {index >= 0 && index < arr.length - 1 ? ', ' : null}
          </span>
        ))}
      </td>
      <td>
        <Link to={`/admin/beacons/${beacon.uuid}`}>
          <button className='btn btn-secondary' type='button'>
            edit
          </button>
        </Link>
      </td>
    </tr>
  );
};
